import { EntityID } from '@/libs/interfaces';
import { createUrl } from '@/utils';

const common = createUrl('api/lk');
const openApi = createUrl('open');
const taxRatesBase = createUrl('open/tax-rates');

const voucherFilter = common('voucher/filter');
const voucherFind = common('voucher/find');

const voucherIdCommon = (id: EntityID) => common(`voucher/${id}`);
const voucherIdOpenApi = (id: EntityID) => openApi(`voucher/${id}`);
const taxRates = taxRatesBase('');

export const receiptsEndpoints = {
  voucherFilter,
  voucherFind,
  voucherIdCommon,
  voucherIdOpenApi,
  taxRates,
};
