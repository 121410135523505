import { IDictionaryEntityDto } from '@/types/IDictionaryEntityDto';
import { INotificationDto } from '@/types/INotificationDto';
import { IContentDto, IDictContentType } from '@/types/IContentDto';
import { IPagedDto } from '@/types/IPagedDto';

export enum MessageChannelType {
  TAXPAYER_PERSONAL_ACCOUNT = 'TAXPAYER_PERSONAL_ACCOUNT',
  EMAIL = 'EMAIL',
}

export interface IMessageDto {
  // Идентификатор записи
  id: string;

  // Дата и время создания
  created: string;

  // Дата и время изменения
  updated: string;

  // Состояние сообщения (uuid)
  status: string;

  // Дата и время отсылки
  sentAt: string;

  // Уведомление
  notification: INotificationDto;

  // Заголовок сообщения
  subject: string;

  // Тело сообщения(TEXT)
  body: string;

  // Тело сообщения(BINARY) string($byte)
  bodyBin: string;

  // Вложения
  attachments: IPagedDto<IContentDto>;

  // Канал доставки уведомления (DictMessageChannel)
  channel: IDictionaryEntityDto;

  // Тип содержимого
  bodyContentType: IDictContentType;

  // Тип нарушения (uuid)
  violationType: string;

  // Вид уведомления
  // Открытие нарушения / Изменение нарушения / Напоминание по нарушению и прочее
  kind: IDictionaryEntityDto;
}
