import { ApiService } from '@/api/services/core';
import { baseApi } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';

import { IBaseCurrency, ICurrency } from '@/libs/interfaces';
import { CurrencyType } from '@/libs/enums';
import { CURRENCY_FRACTURE_PART } from '@/libs/constants';

export class CurrencyService extends ApiService {
  public upload(fileToUpload: File) {
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    return baseApi.post<boolean>(ENDPOINTS.lk.uploadNomenclature, formData);
  }

  public async getCurrencyList(): Promise<ICurrency[]> {
    const { data } = await baseApi.get<IBaseCurrency[]>(
      ENDPOINTS.currency.list,
    );
    const result: ICurrency[] = data.map((currency: IBaseCurrency) => ({
      ...currency,
      sellExchangeRate: currency.sellExchangeRate! * CURRENCY_FRACTURE_PART,
      buyExchangeRate: currency.buyExchangeRate! * CURRENCY_FRACTURE_PART,
      isEdit: false,
      type: CurrencyType.Catalog,
      basePrice: 0,
    }));

    return result;
  }

  public async createCurrency(currency: ICurrency): Promise<void> {
    const baseCurrency: IBaseCurrency = {
      id: currency.id,
      code: currency.code,
      name: currency.name,
      amount: currency.amount,
      buyExchangeRate: currency.buyExchangeRate,
      sellExchangeRate: currency.sellExchangeRate,
      modifyDate: currency.modifyDate,
      hidden: currency.hidden,
    };

    await baseApi.post(ENDPOINTS.currency.create, baseCurrency);
  }

  public async updateCurrency(currency: ICurrency): Promise<void> {
    const baseCurrency: IBaseCurrency = {
      id: currency.id,
      code: currency.code,
      name: currency.name,
      amount: currency.amount,
      buyExchangeRate: currency.buyExchangeRate,
      sellExchangeRate: currency.sellExchangeRate,
      modifyDate: currency.modifyDate,
      hidden: currency.hidden,
    };

    await baseApi.put(ENDPOINTS.currency.update, baseCurrency);
  }

  public async deleteCurrency(currency: ICurrency): Promise<void> {
    await baseApi.delete(ENDPOINTS.currency.deleteEndpoint(currency.id));
  }

  public async uploadCurrencyFile(currencyFile: File): Promise<void> {
    const formData = new FormData();
    formData.append('file', currencyFile, currencyFile.name);

    await baseApi.post(ENDPOINTS.currency.upload, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
