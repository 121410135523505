// DictViolationType - Тип нарушения
export const getDictViolationType = (id: string) =>
  `/api/v1/dict_violation_type/${id}`;
export const putDictViolationType = (id: string) =>
  `/api/v1/dict_violation_type/${id}`;
export const postDictViolationTypeHistory = (id: string) =>
  `/api/v1/dict_violation_type/${id}/history`;
export const postDictViolationTypeSearch = () =>
  `/api/v1/dict_violation_type/search`;
export const postDictViolationType = () => `/api/v1/dict_violation_type/`;

// DictLanguage - Язык
export const getDictLanguage = (id: string) => `/api/v1/dict_language/${id}`;
export const putDictLanguage = (id: string) => `/api/v1/dict_language/${id}`;
export const postDictLanguageSearch = () => `/api/v1/dict_language/search`;
export const postDictLanguageCreate = () => `/api/v1/dict_language/`;

// DictNotificationStatus - Статус уведомления
export const getDictNotificationStatus = (id: string) =>
  `/api/v1/dict_notification_status/${id}`;
export const putDictNotificationStatus = (id: string) =>
  `/api/v1/dict_notification_status/${id}`;
export const postDictNotificationStatusSearch = () =>
  `/api/v1/dict_notification_status/search`;

// DictActiveStatus - Статус активности записи
export const getDictActiveStatus = (id: string) =>
  `/api/v1/dict_active_status/${id}`;
export const putDictActiveStatus = (id: string) =>
  `/api/v1/dict_active_status/${id}`;
export const postDictActiveStatusSearch = () =>
  `/api/v1/dict_active_status/search`;
