import { observable } from 'mobx';
import { RootStores } from '@/store/core/RootStore';
import ListStore from '@/store/core/ListStore';
import { ContentEntity } from '@/entities/ContentEntity';
import { stoCtaNotificationApi } from '@/api';
import { IPagedDto } from '@/types/IPagedDto';
import { ContentTypeCode, IContentDto } from '@/types/IContentDto';
import {
  getContent,
  getContentBodyBin,
  postContentSearch,
} from '@/api/endpoints/notification';
import { downloadFileFromBuffer } from '@/utils';
import { And, ApiFilter, FieldEq } from '@/api/QueryFilter';
import { Sort } from '@/api/Sort';

export default class ContentList extends ListStore<ContentEntity> {
  @observable public messageId: string | null = null;
  @observable public content: ContentEntity | null = null;
  public sort: Sort;

  constructor(messageId: string | null, stores: RootStores) {
    super(stores);

    this.messageId = messageId;
    this.pageSize = 50;
    this.sort = new Sort();
  }

  public async setSort(sort: Sort): Promise<void> {
    this.sort = new Sort(sort.prop, sort.direction);
    this.page = 0;
    await this.fetch();
  }

  public async update(messageId: string): Promise<void> {
    this.messageId = messageId;
    await this.fetch();
  }

  public async fetch(): Promise<void> {
    try {
      this.loading = true;
      const response = await stoCtaNotificationApi.post<IPagedDto<IContentDto>>(
        postContentSearch(),
        {
          filter: this.getFilter(),
          sorts: this.sort.sorts,
          page: {
            page: this.page,
            size: this.pageSize,
          },
        },
      );

      const { data, total } = response.data;
      this.items = data.map(
        (item) => new ContentEntity(item, this.stores.dictionaries),
      );
      this.totalItems = total;
    } catch (e: any) {
      console.error('Fetch Content List: ', e);
    } finally {
      this.loading = false;
    }
  }

  public async fetchById(contentId: string): Promise<void> {
    try {
      const response = await stoCtaNotificationApi.get<IContentDto>(
        getContent(contentId),
      );
      this.content = new ContentEntity(response.data, this.stores.dictionaries);
    } catch (e: any) {
      console.error('Fetch Content: ', e);
    }
  }

  public async downloadById(contentId: string): Promise<void> {
    await this.fetchById(contentId);

    let response = null;
    switch (this.content?.type.code) {
      case ContentTypeCode.TEXT:
      case ContentTypeCode.HTML:
        downloadFileFromBuffer(
          this.content?.name,
          this.content?.body,
          this.content?.type.mediaType,
        );
        break;

      case ContentTypeCode.PDF:
        response = await stoCtaNotificationApi.get<IContentDto>(
          getContentBodyBin(contentId),
          { responseType: 'blob' },
        );
        downloadFileFromBuffer(
          this.content?.name,
          response.data,
          this.content?.type.mediaType,
        );
        break;
    }
  }

  protected getFilter(): ApiFilter | null {
    const children: ApiFilter[] = [];

    children.push(new FieldEq('message.id', [this.messageId as string]));

    if (children.length == 0) {
      return null;
    }

    if (children.length == 1) {
      return children[0];
    }

    const result = new And();
    result.children = children;

    return result;
  }
}
