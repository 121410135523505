import { RouteConfig } from 'vue-router';

const ServicesView = () => import('@/views/services/services.vue');
const ReceiptSettingIndex = () =>
  import('@/views/services/receiptsSettings/index.vue');
const ProductCatalogIndex = () =>
  import('@/views/services/productCatalog/index.vue');
const CurrencyCatalogIndex = () =>
  import('@/views/services/currencyCatalog/index.vue');

export default {
  path: '/services',
  component: ServicesView,
  redirect: '/services/receipts-settings',
  meta: { layout: 'default', public: true },
  children: [
    {
      path: 'receipts-settings',
      name: 'receipts-settings-index',
      component: ReceiptSettingIndex,
    },
    {
      path: 'product-catalog',
      name: 'product-catalog-index',
      component: ProductCatalogIndex,
    },
    {
      path: 'currency-catalog',
      name: 'currency-catalog-index',
      component: CurrencyCatalogIndex,
    },
  ],
} as RouteConfig;
