import { CounterRow } from '@/libs/interfaces';
export class CountersColumn {
  columnName: string;
  rowKey: keyof CounterRow;
  className: string;

  constructor(columnName: string, rowKey: any, className = '') {
    this.columnName = columnName;
    this.rowKey = rowKey;
    this.className = className;
  }
}
