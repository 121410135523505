import { action, computed, observable, override } from 'mobx';
import { RootStores } from '@/store/core/RootStore';
import ListStore from '@/store/core/ListStore';
import { IMessageDto, MessageChannelType } from '@/types/IMessageDto';
import { stoCtaNotificationApi } from '@/api';
import {
  postMessageSearch,
  postNotificationSearch,
  putNotificationRead,
} from '@/api/endpoints/notification';
import {
  And,
  ApiFilter,
  FieldBtw,
  FieldEq,
  FieldIlike,
  FieldIn,
} from '@/api/QueryFilter';
import { Sort } from '@/api/Sort';
import TaxPeriod from '@/entities/TaxPeriod';
import { DictionaryEntity } from '@/entities/DictionaryEntity';
import { MessageEntity } from '@/entities/MessageEntity';
import { IPagedDto } from '@/types/IPagedDto';
import {
  INotificationDto,
  NotificationStatusCode,
} from '@/types/INotificationDto';
import { i18n } from '@/i18n';
import moment from 'moment';

export default class MessagesList extends ListStore<MessageEntity> {
  @observable public notificationKind: string | null;
  @observable public totalUnread = 0;
  @override public sort: Sort;

  constructor(stores: RootStores) {
    super(stores);

    this.notificationKind = 'NOTIFICATION';
    this.sort = new Sort('created', 'DESC');
  }

  @computed
  public get selectedNotificationKind(): DictionaryEntity | null {
    const notificationKind =
      this.stores.dictionaries.notificationKindList.items.find(
        (item) => item.code === this.notificationKind,
      );

    return notificationKind || null;
  }

  @computed
  public get notificationKindLabel(): string {
    return this.selectedNotificationKind != null
      ? this.selectedNotificationKind.title.localizedValue
      : (i18n.t('page_notifications.all-notification-kind') as string);
  }

  @computed
  public get itemsView() {
    return this.items.map((item) => item.getListItemView());
  }

  public async setSort(sort: Sort): Promise<void> {
    this.sort = new Sort(sort.prop, sort.direction);
    this.page = 0;
    await this.fetch();
  }

  @action.bound
  public async setNotificationKind(
    notificationKind: string | null,
  ): Promise<void> {
    console.log(notificationKind);
    this.notificationKind = notificationKind;
    await this.fetch();
  }

  @action.bound
  public async fetchData(): Promise<any> {
    const accountChannel =
      this.stores.dictionaries.messageChannelList.items.find(
        (item) => item.code === MessageChannelType.TAXPAYER_PERSONAL_ACCOUNT,
      );

    if (accountChannel == null) {
      return { items: [], totalItems: 0 };
    }
    const messagesResponse = await stoCtaNotificationApi.post<
      IPagedDto<IMessageDto>
    >(postMessageSearch(), {
      filter: this.createMessagesFilter(accountChannel),
      sorts: this.sort.sorts,
      page: {
        page: this.page,
        size: this.pageSize,
      },
    });

    if (
      messagesResponse.data == null ||
      messagesResponse.data.data == null ||
      messagesResponse.data.data.length == 0
    ) {
      return {
        items: [],
        totalItems: 0,
      };
    }

    const messages: IMessageDto[] = messagesResponse.data.data;
    const totalItems: number = messagesResponse.data.total;
    const notificationsIds = messages.map((item) => item.notification.id);

    const notificationsResponse = await stoCtaNotificationApi.post<
      IPagedDto<INotificationDto>
    >(postNotificationSearch(), {
      filter: this.createNotificationsFilter(notificationsIds),
      page: {
        page: 0,
        size: 10000,
      },
    });

    const notifications: INotificationDto[] =
      notificationsResponse.data?.data || [];

    for (const message of messages) {
      const notification = notifications.find(
        (item) => item.id === message.notification.id,
      );
      if (notification) {
        message.notification = notification;
      }
    }

    return {
      items: messages.map(
        (item) => new MessageEntity(item, this.stores.dictionaries),
      ),
      totalItems: totalItems,
    };
  }

  @action.bound
  public async fetchTotalUnread(): Promise<void> {
    try {
      const accountChannel =
        this.stores.dictionaries.messageChannelList.items.find(
          (item) => item.code === MessageChannelType.TAXPAYER_PERSONAL_ACCOUNT,
        );

      if (accountChannel == null) {
        this.totalUnread = 0;
        return;
      }

      const statusCodes: string[] = [
        NotificationStatusCode.NEW,
        NotificationStatusCode.SENT,
      ];
      const statusIds = this.stores.dictionaries.notificationStatusList.items
        .filter((item) => statusCodes.includes(item.code))
        .map((item) => item.id);

      const response = await stoCtaNotificationApi.post<IPagedDto<IMessageDto>>(
        postMessageSearch(),
        {
          filter: this.createTotalUnreadFilter(accountChannel, statusIds),
          page: {
            page: 0,
            size: 1,
          },
        },
      );

      this.totalUnread = response.data?.total || 0;
    } catch (e) {
      console.error(e);
      this.totalUnread = 0;
    }
  }

  @action.bound
  public async markAsRead(notificationId: string): Promise<void> {
    try {
      const messageEntityIndex = this.items.findIndex(
        (item) => item.notification.id === notificationId,
      );
      const readStatus =
        this.stores.dictionaries.notificationStatusList.items.find(
          (item) => item.code === NotificationStatusCode.READ,
        );

      if (messageEntityIndex > -1 && readStatus != null) {
        const message = this.items[messageEntityIndex];
        if (message.status.code === NotificationStatusCode.READ) {
          return;
        }

        await stoCtaNotificationApi.put(putNotificationRead(notificationId));
        message.notification.status = readStatus;
        message.status = readStatus;

        if (this.totalUnread > 0) {
          this.totalUnread = this.totalUnread - 1;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  private createMessagesFilter(
    accountChannel: DictionaryEntity,
  ): ApiFilter | null {
    const children: ApiFilter[] = [];

    children.push(
      new FieldEq('notification.tin', [this.stores.user.taxNumber || '']),
    );
    children.push(new FieldEq('channel.code', [accountChannel.code]));

    const periodFrom = TaxPeriod.fromDateString(
      moment(new Date()).format('2023-01-01'),
    );
    const periodTo = TaxPeriod.fromDateString(
      moment(new Date()).format('YYYY-12-31'),
    );
    const taxPeriodFilter =
      periodFrom === periodTo
        ? new FieldEq('notification.taxPeriod', [periodFrom])
        : new FieldBtw('notification.taxPeriod', [periodFrom, periodTo]);
    children.push(taxPeriodFilter);

    if (this.selectedNotificationKind != null) {
      children.push(
        new FieldEq('notification.kind.id', [this.selectedNotificationKind.id]),
      );
    }

    if (this.search != null && this.search.trim() !== '') {
      children.push(new FieldIlike('subject', [`%${this.search}%`]));
    }

    if (children.length == 0) {
      return null;
    }

    if (children.length == 1) {
      return children[0];
    }

    const result = new And();
    result.children = children;

    return result;
  }

  private createNotificationsFilter(ids: string[]): ApiFilter | null {
    const children: ApiFilter[] = [];

    // HERE
    children.push(new FieldIn('id', [...ids]));

    if (children.length == 0) {
      return null;
    }

    if (children.length == 1) {
      return children[0];
    }

    const result = new And();
    result.children = children;

    return result;
  }

  private createTotalUnreadFilter(
    accountChannel: DictionaryEntity,
    statusIds: string[],
  ): ApiFilter | null {
    const children: ApiFilter[] = [];

    // HERE
    children.push(
      new FieldEq('notification.tin', [this.stores.user.taxNumber || '']),
    );
    children.push(new FieldEq('channel.code', [accountChannel.code]));
    children.push(new FieldIn('status.id', [...statusIds]));

    if (children.length == 0) {
      return null;
    }

    if (children.length == 1) {
      return children[0];
    }

    const result = new And();
    result.children = children;

    return result;
  }
}
