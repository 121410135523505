import { createUrl } from '@/utils';

const lk = createUrl('api/lk');

const cashRegisterId = (id: number) => lk(`cash-register/${id}`);
const regNumber = (regNumber: string) => lk(`reports/regnumber/${regNumber}`);
const shift = (shiftId: string) => lk(`shift/${shiftId}/vouchers`);
const transactionList = (regNum: string) => lk(`shifts/regnum/${regNum}`);
const shifts = lk('shifts');
const xreport = (shiftId: string) => lk(`shift/open-report/${shiftId}`);
const openreport = (shiftId: string) => lk(`shift/open-report/${shiftId}`);
const zreport = (shiftId: string) => lk(`shift/z-report/${shiftId}`);

export const crDetailsEndpoints = {
  cashRegisterId,
  regNumber,
  shift,
  transactionList,
  shifts,
  xreport,
  openreport,
  zreport,
};
