import { ApiService } from '@/api/services/core';
import { baseApi } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';

export class NotificationService extends ApiService {
  public getEmailState() {
    return baseApi.get(ENDPOINTS.notifications.email);
  }

  public getSmsState() {
    return baseApi.get(ENDPOINTS.notifications.sms);
  }

  public setEmailState(newState: boolean) {
    const url = ENDPOINTS.notifications.email;
    const method = newState ? baseApi.put(url, {}) : baseApi.delete(url);
    return method;
  }

  public setSmsState(newState: boolean) {
    const url = ENDPOINTS.notifications.sms;
    const method = newState ? baseApi.put(url, {}) : baseApi.delete(url);
    return method;
  }
}
