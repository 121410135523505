import { ApiService } from '@/api/services/core';
import { baseApi } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { ICodeName } from '@/api/services/infoService/types';

export class InfoService extends ApiService {
  public getTaxAuthorityDepartments() {
    return baseApi.get<ICodeName[]>(ENDPOINTS.info.taxAuthorityDepartments);
  }

  public getLegalPersonActivities() {
    return baseApi.get<ICodeName[]>(ENDPOINTS.info.legalPersonActivities);
  }
}
