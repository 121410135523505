import { ApiService } from '@/api/services/core';
import { baseApi } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';

export class NomenclatureService extends ApiService {
  public upload(fileToUpload: File) {
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    return baseApi.post<boolean>(ENDPOINTS.lk.uploadNomenclature, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
