import { createUrl } from '@/utils';

const stats = createUrl('stats/ticket');
const grouped = stats('legal-person/grouped');
const common = stats('summary/uid');

export const statsEndpoints = {
  grouped,
  common,
};
