import { IDictionaryEntityDto } from '@/types/IDictionaryEntityDto';
import LocalizedJson from '@/entities/LocalizedJson';

export class DictionaryEntity implements IDictionaryEntityDto {
  // Идентификатор записи
  public id: string;

  // Дата и время создания
  public created: string;

  // Дата и время изменения
  public updated: string;

  // Локализованная строка, key=locale, value=localized string
  public title: LocalizedJson;

  // Код записи (READ-ONLY)
  public code: string;

  constructor(dto: IDictionaryEntityDto | null) {
    if (dto == null) {
      this.id = '';
      this.created = '';
      this.updated = '';
      this.title = new LocalizedJson(null);
      this.code = '';

      return;
    }

    this.id = dto.id;
    this.created = dto.created;
    this.updated = dto.updated;
    this.title = new LocalizedJson(dto.title);
    this.code = dto.code;
  }

  public toLocale(): Omit<IDictionaryEntityDto, 'title'> & { title: string } {
    return {
      id: this.id,
      created: this.created,
      updated: this.updated,
      title: this.title.localizedValue,
      code: this.code,
    };
  }
}
