import { observable, action } from 'mobx';
import { RootStores } from '@/store/core/RootStore';
import StoreConstructor from '@/store/core/StoreConstructor';
import services from '@/api/services';
import { IDevicesGroup } from '@/api/services/crService/type';
import {
  IFilterForm,
  IFilterFormCounters,
  CrStatus,
  ICrExpirationWarning,
} from '@/libs/interfaces';
import { convertAddressObjectToString } from '@/utils';
import { IDevicesGroupItemWithStats } from '@/api/services/crService/type';

export default class CashRegister extends StoreConstructor {
  @observable _receivedDevicesGroups: IDevicesGroup[] = [];

  constructor(stores: RootStores) {
    super(stores);
  }

  inProgress = true;
  receivedDevicesGroups: IDevicesGroup[] = [];
  @observable devicesGroups: IDevicesGroup[] = [];
  expirationWarnings: ICrExpirationWarning[] = [];
  filterData: IFilterForm = {
    allStatuses: false,
    online: true,
    offline: true,
  };
  filterFormCounters: IFilterFormCounters = {
    activeCount: 0,
    inactiveCount: 0,
    deregisteredCount: 0,
  };
  addressString = '';
  fnExpirationWarningDays = 30;
  totalCashRegisters?: number;

  // private currentIndex: number = 0;
  // private isFirstBlockRendered: boolean = false;

  public async ngOnInit(): Promise<void> {
    await this.fetchDevicesGroups();
    this.totalCashRegisters = this.devicesGroups.reduce(
      (sum: number, current: IDevicesGroup) =>
        sum + current.cashRegisters.length,
      0,
    );
  }

  public get filteredDevicesGroups(): IDevicesGroup[] {
    return (
      (this.addressString
        ? this.devicesGroups.filter((x: IDevicesGroup) =>
            convertAddressObjectToString(x.address)
              .toLowerCase()
              .includes(this.addressString.toLowerCase()),
          )
        : this.devicesGroups
      )?.filter((y) => y.cashRegisters.length) || []
    );
  }

  public searchByAddress(addressStr: string) {
    this.addressString = addressStr;

    // if (this.devicesGroupContainer != null) {
    //   this.currentIndex = 0;
    //   this.devicesGroupContainer.clear();
    //   this.renderDevicesGroupsBlock();
    // }
  }

  public async openGroup(group: IDevicesGroup) {
    group.isExpanded = true;

    const { data } = await services.crService.getListForLocation(group.id);

    data.forEach((kkt) => {
      const itemSelectedGroup = group.cashRegisters.find(
        (x) => x.id === kkt.id,
      );
      if (itemSelectedGroup) {
        itemSelectedGroup.stats = kkt.stats;
      }
    });
  }

  public filterDevicesGroups(filterData: IFilterForm) {
    this.filterData = { ...filterData } as IFilterForm;
    const filteredGroups = this.filterList(
      this.receivedDevicesGroups,
      this.filterData,
    );
    this.setDevicesGroups(filteredGroups);

    // if (this.devicesGroupContainer != null) {
    //   this.currentIndex = 0;
    //   this.devicesGroupContainer.clear();
    //   this.renderDevicesGroupsBlock();
    // }
  }

  private async fetchDevicesGroups(): Promise<void> {
    try {
      this.inProgress = true;
      const { data } = await services.crService.getList();

      this.receivedDevicesGroups = data;

      this.filterDevicesGroups(this.filterData);
    } catch (e) {
      console.error('error fetchDevicesGroups: ', e);
    } finally {
      this.inProgress = false;
    }
  }

  private setDevicesGroups(devicesGroups: IDevicesGroup[]) {
    this.devicesGroups = devicesGroups;
    this.filterFormCounters = this.mapFilterFormCounters(this.devicesGroups);
    this.refreshExpirationWarnings(devicesGroups);
  }

  private refreshExpirationWarnings(devicesGroups: IDevicesGroup[]) {
    this.expirationWarnings = this.mapExpirationWarnings(devicesGroups);
  }

  private mapFilterFormCounters(
    devicesGroups: IDevicesGroup[],
  ): IFilterFormCounters {
    const counters: IFilterFormCounters = {
      activeCount: 0,
      inactiveCount: 0,
      deregisteredCount: 0,
    };

    if (!devicesGroups || !devicesGroups.length) {
      return counters;
    }

    devicesGroups.forEach((group: IDevicesGroup) => {
      if (!group.cashRegisters || !group.cashRegisters.length) {
        return;
      }
      group.cashRegisters.forEach((kkt) => {
        if (kkt.online) {
          counters.activeCount++;
        }
        if (!kkt.online && kkt.status !== CrStatus.DEACTIVATED) {
          counters.inactiveCount++;
        }
        if (kkt.status === CrStatus.DEACTIVATED) {
          counters.deregisteredCount++;
        }
      });
    });
    return counters;
  }

  private mapExpirationWarnings(
    devicesGroups: IDevicesGroup[] = [],
  ): ICrExpirationWarning[] {
    return devicesGroups
      .reduce(
        (acc: IDevicesGroupItemWithStats[], g: IDevicesGroup) =>
          acc.concat(g.cashRegisters),
        [],
      )
      .filter((kkt: IDevicesGroupItemWithStats) => {
        return (
          kkt.status !== CrStatus.DEACTIVATED &&
          kkt.fnActivationDaysLeft &&
          kkt.fnActivationDaysLeft < this.fnExpirationWarningDays
        );
      })
      .map((kkt: IDevicesGroupItemWithStats) => {
        return {
          regNumber: kkt.regNumber,
          kktId: kkt.id,
          daysLeft: kkt.fnActivationDaysLeft,
        } as ICrExpirationWarning;
      });
  }

  private filterList(
    list: IDevicesGroup[],
    filterData: IFilterForm,
  ): IDevicesGroup[] {
    const isOnline = (cr: IDevicesGroupItemWithStats) => cr.online;
    const isOffline = (cr: IDevicesGroupItemWithStats) =>
      !cr.online && cr.status !== CrStatus.DEACTIVATED;
    const isAllStatuses = (cr: IDevicesGroupItemWithStats) =>
      cr.status === CrStatus.DEACTIVATED;

    const result: IDevicesGroup[] = list.map((group: IDevicesGroup) => {
      return {
        ...group,
        totalCashRegistersCount: group.cashRegisters.length,
        cashRegisters: group.cashRegisters.filter(
          (cr: IDevicesGroupItemWithStats) => {
            if (
              filterData.online &&
              filterData.offline &&
              filterData.allStatuses
            ) {
              return isOnline(cr) || isOffline(cr) || isAllStatuses(cr);
            }

            if (filterData.online && filterData.offline) {
              return isOnline(cr) || isOffline(cr);
            }

            if (filterData.offline && filterData.allStatuses) {
              return isOffline(cr) || isAllStatuses(cr);
            }

            if (filterData.online && filterData.allStatuses) {
              return isOnline(cr) || isAllStatuses(cr);
            }

            if (filterData.online) {
              return isOnline(cr);
            }

            if (filterData.offline) {
              return isOffline(cr);
            }

            if (filterData.allStatuses) {
              return isAllStatuses(cr);
            }
          },
        ),
      };
    });

    return result;
  }

  // public renderDevicesGroupsBlock(): void {
  //   const length = this.filteredDevicesGroups.length;
  //   const blockSize = 15;
  //   const nextIndex = this.currentIndex + blockSize;

  //   for (let n = this.currentIndex; n <= nextIndex; n++) {
  //     if (n >= length) {
  //       break;
  //     }

  //     const context = {
  //       group: this.filteredDevicesGroups[n],
  //     };

  //     this.devicesGroupContainer.createEmbeddedView(
  //       this.devicesGroupTemplate,
  //       context,
  //     );
  //   }

  //   this.currentIndex = this.currentIndex + blockSize + 1;
  // }
}
