import { RouteConfig } from 'vue-router';

const MessagesView = () => import('@/views/messages/messages.vue');
const MessagesIndex = () => import('@/views/messages/index.vue');

export default {
  path: '/messages',
  component: MessagesView,
  children: [
    {
      path: '',
      name: 'messages-index',
      component: MessagesIndex,
    },
  ],
} as RouteConfig;
