import { ApiService } from '@/api/services/core';
import { baseApi } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import {
  IDetailedReceipt,
  IReceiptList,
  EntityID,
  ITaxRate,
} from '@/libs/interfaces';

export class ReceiptService extends ApiService {
  public getReceipts(body: any) {
    return baseApi.post<IReceiptList>(ENDPOINTS.receipts.voucherFilter, body);
  }

  public findReceipts(params: any) {
    return baseApi.get<IReceiptList>(ENDPOINTS.receipts.voucherFind, {
      params,
      paramsSerializer: {
        indexes: null,
      },
    });
  }

  public getReceipt(id: EntityID, isGuest = false) {
    const url = isGuest
      ? ENDPOINTS.receipts.voucherIdOpenApi(id)
      : ENDPOINTS.receipts.voucherIdCommon(id);
    const params = { include_items: true };
    return baseApi.get<IDetailedReceipt>(url, { params: params });
  }

  public getTaxRates() {
    return baseApi.get<ITaxRate[]>(ENDPOINTS.receipts.taxRates);
  }
}
