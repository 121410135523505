import { IDictNotificationType } from '@/types/INotificationDto';
import { DictionaryEntity } from '@/entities/DictionaryEntity';

export default class NotificationTypeEntity extends DictionaryEntity {
  // Интервал напоминаний в днях
  remindInterval: number;

  // Количество напоминаний
  remindCount: number;

  constructor(dto: IDictNotificationType | null) {
    super(dto);

    if (dto == null) {
      this.remindInterval = 0;
      this.remindCount = 0;

      return;
    }

    this.remindInterval = dto.remindInterval;
    this.remindCount = dto.remindCount;
  }

  public toLocale(): Omit<IDictNotificationType, 'title'> & { title: string } {
    return {
      ...super.toLocale(),
      remindInterval: this.remindInterval,
      remindCount: this.remindCount,
    };
  }
}
