import StoreConstructor from '@/store/core/StoreConstructor';
import { action, computed, observable } from 'mobx';
import { DictionaryEntity } from '@/entities/DictionaryEntity';
import { RootStores } from '@/store/core/RootStore';
import { stoCtaNotificationApi } from '@/api';
import { IPagedDto } from '@/types/IPagedDto';
import { IDictionaryEntityDto } from '@/types/IDictionaryEntityDto';
import { postDictNotificationKindSearch } from '@/api/endpoints/notification';
import { i18n } from '@/i18n';

export default class NotificationKindList extends StoreConstructor {
  @observable items: DictionaryEntity[] = [];
  @observable loading = false;
  @observable totalItems = 0;

  constructor(stores: RootStores) {
    super(stores);
  }

  @computed
  public get options(): { label: string; value: string | null }[] {
    const allOption = {
      label: i18n.t('page_notifications.all-notification-kind') as string,
      value: null,
    };

    const mappedOptions = this.items
      .filter((item) => item.title != null)
      .map((item) => ({
        label: item.title.localizedValue,
        value: item.code,
      }));

    return [allOption, ...mappedOptions];
  }

  @action.bound
  public async fetch(): Promise<void> {
    try {
      this.loading = true;
      const response = await stoCtaNotificationApi.post<
        IPagedDto<IDictionaryEntityDto>
      >(postDictNotificationKindSearch(), { page: { page: 0, size: 10000 } });

      const { data, total } = response.data;
      this.items = data.map((item) => new DictionaryEntity(item));
      this.totalItems = total;
    } catch (e) {
      console.error(e);
      this.items = [];
      this.totalItems = 0;
    } finally {
      this.loading = false;
    }
  }
}
