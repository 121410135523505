import { IPagedDto } from '@/types/IPagedDto';
import { IJobInfoDto } from '@/types/IJobInfoDto';
import LocalizedJson from '@/entities/LocalizedJson';
import Entity from '@/entities/Entity';
import { DictionaryEntity } from '@/entities/DictionaryEntity';

export default class JobInfoEntity extends Entity {
  // периодичность (cron)
  public cronExpression: string | null;

  // Статус активности
  public status: DictionaryEntity;

  // Тип задачи
  public type: DictionaryEntity;

  // Сервис, который исполняет задачу
  public service: DictionaryEntity;

  // Класс, реализующий задачу (READ-ONLY)
  public jobClassName: string;

  // Локализованная строка, key=locale, value=localized string
  public description: LocalizedJson;

  // Параметры запуска (YML)
  public params: string;

  // можно ли редактировать (READ-ONLY)
  public editable: boolean;

  // Будут ли писаться заиси в журнал исполнения (READ-ONLY)
  public loggable: boolean;

  // Есть ли дочерние задачи (чисто для удобства UI) (READ-ONLY)
  public hasChildren: boolean;

  // дочерние задачи
  public children: IPagedDto<JobInfoEntity>;

  // родительские задачи
  public parents: IPagedDto<JobInfoEntity>;

  // Схема параметров запуска (JSON)
  public paramsSchema: any;

  constructor(dto: IJobInfoDto) {
    super(dto);

    if (dto == null) {
      this.cronExpression = null;
      this.status = new DictionaryEntity(null);
      this.type = new DictionaryEntity(null);
      this.service = new DictionaryEntity(null);
      this.jobClassName = '';
      this.description = new LocalizedJson(null);
      this.params = '';
      this.editable = false;
      this.loggable = false;
      this.hasChildren = false;

      this.children = {
        data: [],
        page: { page: 0, size: 0 },
        total: 0,
      };

      this.parents = {
        data: [],
        page: { page: 0, size: 0 },
        total: 0,
      };

      return;
    }

    this.cronExpression = dto.cronExpression;
    this.status = new DictionaryEntity(dto.status);
    this.type = new DictionaryEntity(dto.type);
    this.service = new DictionaryEntity(dto.service);
    this.jobClassName = dto.jobClassName;
    this.description = new LocalizedJson(dto.description);
    this.params = dto.params;
    this.editable = dto.editable;
    this.loggable = dto.loggable;
    this.hasChildren = dto.hasChildren;

    this.children = {
      data: (dto.children?.data || []).map((item) => new JobInfoEntity(item)),
      page: dto.children?.page,
      total: dto.children?.total || 0,
    };

    this.parents = {
      data: (dto.parents?.data || []).map((item) => new JobInfoEntity(item)),
      page: dto.parents?.page,
      total: dto.parents?.total || 0,
    };

    this.paramsSchema = dto.paramsSchema;
  }

  public get statusBadge(): { color: string; label: string } {
    return {
      label: this.status.title.localizedValue,
      color: this.isEnabled ? 'success' : 'neutral',
    };
  }

  public get isEnabled(): boolean {
    return this.status.code === 'ENABLED';
  }

  // public getJobInfoListItemView(): IJobInfoListItemView {
  //   const category = CATEGORIES[0];
  //
  //   return {
  //     id: this.id,
  //     created: this.created.dateTime,
  //     updated: this.updated.date,
  //     title: this.title.localizedValue,
  //     category: category.title.localizedValue,
  //     statusCodeColorStyle: this.statusBadge.color,
  //     statusTitle: this.statusBadge.label,
  //   };
  // }
}
