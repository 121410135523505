import { ApiService } from '@/api/services/core';
import { baseApi } from '@/api';
import {
  AuthData,
  Credentials,
  UserData,
  IPersonCredentials,
} from '@/api/services/userService/types';
import { ENDPOINTS } from '@/api/endpoints';

export class UserService extends ApiService {
  public login(props: Credentials) {
    return baseApi.post<AuthData>(ENDPOINTS.auth.login, props);
  }

  public refresh(props: { refreshToken: string }) {
    return baseApi.post<AuthData>(
      ENDPOINTS.auth.refresh,
      {},
      {
        headers: {
          'Refresh-Token': props.refreshToken,
        },
      },
    );
  }

  public getCurrentUser() {
    return baseApi.get<UserData>(ENDPOINTS.auth.user);
  }

  public signUp(registrationInfo: IPersonCredentials) {
    return baseApi.post<AuthData>(
      ENDPOINTS.auth.registration,
      registrationInfo,
    );
  }

  public sendEmail(email: string) {
    return baseApi.post(ENDPOINTS.user.recovery, { login: email });
  }

  public sendPassword(token: string, password: string) {
    const body = { token, password };
    return baseApi.post(ENDPOINTS.user.recoveryPass, body);
  }

  public confirmCode(code: string) {
    return baseApi.post<AuthData>(ENDPOINTS.auth.confirmCode(code));
  }
}
