import { numGroup } from '@/utils';

export default class Money {
  private readonly _money: number;

  constructor(money: number) {
    this._money = money;
  }

  public get money(): string {
    return numGroup(this._money);
  }

  public get value(): number {
    return this._money;
  }
}
