import { createUrl } from '@/utils';

const currency = createUrl('api/currency');

const create = currency('create');

const update = currency('update');

const deleteEndpoint = (id: number) => currency(`delete/${id}`);

const list = currency('list');

const upload = currency('legal-person');

export const currencyEndpoints = {
  create,
  update,
  deleteEndpoint,
  list,
  upload,
};
