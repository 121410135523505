
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { PropType } from 'vue';

type NumGroupConfig = {
  incorrectSign?: string;
  fraction?: boolean;
  fractionDigits?: number;
};
const defaultConfig: NumGroupConfig = {
  incorrectSign: '—',
  fraction: true,
  fractionDigits: 2,
};

@Component({
  name: 'FormattedMoney',
})
export default class FormattedMoney extends Vue {
  @Prop({
    type: Number,
  })
  number!: number;

  @Prop({
    type: Object as PropType<NumGroupConfig>,
  })
  config!: NumGroupConfig;

  get formattedPrice() {
    return this.numGroup(this.number / 100, this.config);
  }

  numGroup(value: number | null | undefined | '', options?: NumGroupConfig) {
    const opts = Object.assign(
      { ...defaultConfig },
      options || {},
    ) as Required<NumGroupConfig>;
    if (value === null || value === undefined || value === '')
      return opts.incorrectSign;
    return Number(value.toFixed(2)).toLocaleString(undefined, {
      ...(opts.fraction && {
        minimumFractionDigits: opts.fractionDigits,
        maximumFractionDigits: opts.fractionDigits,
      }),
    });
  }
}
