import { ILocalizedJson } from '@/types/ILocalizedJson';
import { i18n } from '@/i18n';

export default class LocalizedJson implements ILocalizedJson {
  public readonly en: string;
  public readonly ru: string;

  constructor(dto: ILocalizedJson | null) {
    if (dto == null) {
      this.en = '';
      this.ru = '';

      return;
    }

    this.en = dto.en;
    this.ru = dto.ru;
  }

  public get localizedValue(): string {
    const anyThis = this as any;
    return anyThis[i18n.locale] || anyThis[i18n.fallbackLocale as string];
  }
}
