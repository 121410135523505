import User from '@/store/user';
import CurrencyCatalog from '@/store/currency/currencyCatalog';
import Settings from '@/store/settings';
import CashRegister from '@/store/cashRegister';
import FiscalMemories from '@/store/fiscalMemories';
import CurrencyStore from '@/store/currency';
import TaxSystemStore from '@/store/taxSystem';
import BusinessObjectsDictionaryStore from '@/store/businesObjectDictionary';
import ReceiptGridStore from '@/store/receiptGridStore';
import CRDetails from '@/store/crDetails';
import Message from '@/store/notification/message';
import ContentList from '@/store/notification/contentList';
import MessagesList from '@/store/notification/messagesList';
import DictionariesStore from '@/store/dictionaries/dictionaries';

export class RootStores {
  user = new User(this);
  currencyCatalog = new CurrencyCatalog(this);
  settings = new Settings(this);
  cashRegister = new CashRegister(this);
  fiscalMemories = new FiscalMemories(this);
  currencyStore = new CurrencyStore(this);
  taxSystemStore = new TaxSystemStore(this);
  businessObjectsDictionaryStore = new BusinessObjectsDictionaryStore(this);
  receiptGridStore = new ReceiptGridStore(this);
  crDetails = new CRDetails(this);

  dictionaries = new DictionariesStore(this);
  messagesList = new MessagesList(this);
  contentList = new ContentList(null, this);
  message = new Message('', this);
}

const stores = new RootStores();

export default stores;
