import { ApiService } from '@/api/services/core';
import { baseApi } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';

export class CrTransactionsListService extends ApiService {
  public getList(regNum: string, onlyTicketFds = true) {
    const params = { onlyTicketFds };
    return baseApi.get(ENDPOINTS.crDetails.transactionList(regNum), { params });
  }
}
