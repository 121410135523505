import { IMessageDto } from '@/types/IMessageDto';
import DateTime from '@/entities/DateTime';
import { IPagedDto } from '@/types/IPagedDto';
import { DictionaryEntity } from '@/entities/DictionaryEntity';
import NotificationEntity from '@/entities/NotificationEntity';
import { ContentEntity, DictContentTypeEntity } from '@/entities/ContentEntity';
import { NotificationStatusCode } from '@/types/INotificationDto';
import DictionariesStore from '@/store/dictionaries/dictionaries';
import { ViolationTypeEntity } from '@/entities/ViolationTypeEntity';

export class MessageEntity {
  // Идентификатор записи
  public id: string;

  // Дата и время создания
  public created: DateTime;

  // Дата и время изменения
  public updated: DateTime;

  // Статус сообщения (DictMessageStatus)
  public status: DictionaryEntity;

  // Дата и время отсылки
  public sentAt: DateTime;

  // Уведомление
  public notification: NotificationEntity;

  // Заголовок сообщения
  public subject: string;

  // Тело сообщения(TEXT)
  public body: string;

  // Тело сообщения(BINARY) string($byte)
  public bodyBin: string;

  // Содержимое сообщения
  public attachments: IPagedDto<ContentEntity>;

  // Канал доставки уведомления (DictMessageChannel)
  public channel: DictionaryEntity;

  // Тип содержимого
  public bodyContentType: DictContentTypeEntity;

  // Тип нарушения
  public violationType: ViolationTypeEntity;

  public kind: DictionaryEntity;

  constructor(dto: IMessageDto | null, dictionaries: DictionariesStore) {
    if (dto == null) {
      this.id = '';
      this.created = new DateTime(null);
      this.updated = new DateTime(null);
      this.status = new DictionaryEntity(null);
      this.notification = new NotificationEntity(null, dictionaries);
      this.subject = '';
      this.body = '';
      this.bodyBin = '';
      this.attachments = {
        data: [],
        page: { page: 0, size: 0 },
        total: 0,
      };
      this.sentAt = new DateTime(null);
      this.channel = new DictionaryEntity(null);
      this.bodyContentType = new DictContentTypeEntity(null);
      this.violationType = new ViolationTypeEntity(null);
      this.kind = new DictionaryEntity(null);

      return;
    }

    const status = dictionaries.notificationStatusList.items.find(
      (item: DictionaryEntity) => item.id === dto.status,
    );

    const violationType = dictionaries.violationTypesList.items.find(
      (item: ViolationTypeEntity) => item.id === dto.violationType,
    );

    this.id = dto.id;
    this.created = new DateTime(dto.created);
    this.updated = new DateTime(dto.updated);
    this.status = status!;
    this.notification = new NotificationEntity(dto.notification, dictionaries);
    this.subject = dto.subject;
    this.body = dto.body;
    this.bodyBin = dto.bodyBin;
    this.attachments = {
      data: (dto.attachments?.data || []).map(
        (item) => new ContentEntity(item, dictionaries),
      ),
      page: { ...dto.attachments?.page },
      total: dto.attachments?.total || 0,
    };
    this.sentAt = new DateTime(dto.sentAt);
    this.channel = new DictionaryEntity(dto.channel);
    this.bodyContentType = new DictContentTypeEntity(dto.bodyContentType);
    this.violationType = violationType!;
    this.kind = new DictionaryEntity(dto.kind);
  }

  public get isRead(): boolean {
    return (
      this.status != null && this.status.code === NotificationStatusCode.READ
    );
  }

  public getListItemView() {
    return {
      messageId: this.id,
      notificationId: this.notification.id,
      type: this.notification.kind.title.localizedValue,
      created: this.notification.created.dateTime,
      isRead: this.isRead,
      subjectShort:
        this.subject.length > 100
          ? this.subject.substring(0, 100) + '...'
          : this.subject,
      subjectFull: this.subject,
    };
  }
}
