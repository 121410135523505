import { ApiService } from '@/api/services/core';
import { baseApi } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { ITaxRate } from './type';
import {
  ITaxSystem,
  ICodeName,
  IFormatAndLogicControlError,
} from '@/libs/interfaces';

export class OpenService extends ApiService {
  public getTaxRates() {
    return baseApi.get<ITaxRate[]>(ENDPOINTS.open.taxRates);
  }

  public refreshTaxSystems() {
    return baseApi.get<ITaxSystem[]>(ENDPOINTS.open.taxSystem);
  }

  public getEntrepreneurshipObjects() {
    return baseApi.get<ICodeName[]>(ENDPOINTS.open.entrepreneurshipObjects);
  }

  public getBusinessActivities() {
    return baseApi.get<ICodeName[]>(ENDPOINTS.open.businessActivities);
  }

  public getTaxAuthorityDepartments() {
    return baseApi.get<ICodeName[]>(ENDPOINTS.open.taxAuthorityDepartments);
  }

  public getLegalPersonActivities() {
    return baseApi.get<ICodeName[]>(ENDPOINTS.open.legalPersonActivities);
  }

  public getFormatAndLogicControlErrors() {
    return baseApi.get<IFormatAndLogicControlError[]>(
      ENDPOINTS.open.formatAndLogicControlErrors,
    );
  }
}
