import { KktStatusEnum, TaxiCompanyTop } from '../../enums/index';
import { IReceipt } from '../index';

export interface ITaxiMarker {
  id: number | string;
  coord: {
    lat: number;
    lng: number;
  };
  regNumber: string;
  fdNumber: number;
  sum: number;
  dateTime: string;
  ticket: ITaxiTicket;
  userName?: string;
  position?: TaxiCompanyTop;
}

export interface ITaxiCluster {
  id: number | string;
  elements: ITaxiTicket[];
  coord: {
    lat: number;
    lng: number;
  };
  sum: number;
  count: number;
}

// export interface ITaxiReceipt extends IReceipt {
//     coord: {
//         lat: number;
//         lng: number;
//     };
//     lpId: number | string;
// }

export interface ITaxiClusterResponse {
  point: { lat: number; lon: number };
  elements: ITaxiTicket[];
}

export interface ITaxiTicket {
  point: { lat: number; lon: number };
  regNumber: string;
  fdNumber: number;
  dateTime: string;
  sum: number;
  userName?: string;
}

export interface ITaxiStats {
  dayFiscalRegisters: number;
  dayTaxPayers: number;
  dayTickets: number;
  dayTotal: number;
  dayVat: number;
  daySt: number;
  fiscalRegisters: number;
  taxPayers: number;
  tickets: number;
  total: number;
  vat: number;
  st: number;
}

export interface ICrInfo {
  id: number;
  fiscalMemory: {
    serialNumber: string;
  };
  legalPerson: {
    id: number;
    name: string;
    tin: string;
  };
  location: {
    id: number;
    name: string;
  };
  taxData: {
    calcItemAttributes: number[];
    taxSystems: number[];
    vatPayer: boolean;
  };
  online: boolean;
  regDate: string;
  regNumber: string;
  status: KktStatusEnum;
  position?: TaxiCompanyTop;
}

export interface IClustersParams {
  lpName: string;
  regNumber: string;
  lastMinutes: DisplayPeriod;
  company: string;
}

export enum DisplayPeriod {
  DAY = 0,
  TWOMINUTES = 2,
  FIVEMINUTES = 5,
  FIFTEENMINUTES = 15,
  HOUR = 60,
}

export interface ITaxiCompanyTop {
  tin: string;
  name: string;
  revenue: number;
  crRegNumbers: string[];
  position?: TaxiCompanyTop;
}
