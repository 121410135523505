import { IDictViolationTypeDto } from '@/types/IDictViolationTypeDto';
import Entity from '@/entities/Entity';
import LocalizedJson from '@/entities/LocalizedJson';

export class ViolationTypeEntity extends Entity {
  // Локализованная строка, key=locale, value=localized string
  fullTitle: LocalizedJson;

  // Локализованная строка, key=locale, value=localized string
  description: LocalizedJson;

  // Локализованная строка, key=locale, value=localized string
  taxArticle: LocalizedJson;

  // Локализованная строка, key=locale, value=localized string
  fine: LocalizedJson;

  // Obsolete?
  openThreshold: number;

  // Obsolete?
  closeThreshold: number;

  constructor(dto: IDictViolationTypeDto | null) {
    super(dto);

    if (dto == null) {
      this.fullTitle = new LocalizedJson(null);
      this.description = new LocalizedJson(null);
      this.taxArticle = new LocalizedJson(null);
      this.fine = new LocalizedJson(null);
      this.openThreshold = 0;
      this.closeThreshold = 0;

      return;
    }

    this.fullTitle = new LocalizedJson(dto.fullTitle);
    this.description = new LocalizedJson(dto.description);
    this.taxArticle = new LocalizedJson(dto.taxArticle);
    this.fine = new LocalizedJson(dto.fine);
    this.openThreshold = dto.openThreshold;
    this.closeThreshold = dto.closeThreshold;
  }

  public toLocale(): Omit<
    IDictViolationTypeDto,
    'title' | 'fullTitle' | 'description' | 'taxArticle' | 'fine'
  > & {
    title: string;
    fullTitle: string;
    description: string;
    taxArticle: string;
    fine: string;
  } {
    return {
      id: this.id,
      created: this.created.dateTime,
      updated: this.updated.dateTime,
      title: this.title.localizedValue,
      code: this.code,
      fullTitle: this.fullTitle.localizedValue,
      description: this.fullTitle.localizedValue,
      taxArticle: this.taxArticle.localizedValue,
      fine: this.fine.localizedValue,
      openThreshold: this.openThreshold,
      closeThreshold: this.closeThreshold,
    };
  }
}
