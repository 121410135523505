import { createUrl } from '@/utils';

const auth = createUrl('');
const reg = createUrl('service-api');

const login = auth('login');
const refresh = auth('refresh');
const user = auth('me');
const registration = reg('register');
const confirmCode = (code: string) => reg(`register/confirm?key=${code}`);

export const authEndpoints = {
  login,
  refresh,
  user,
  registration,
  confirmCode,
};
