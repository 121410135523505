import { RouteConfig } from 'vue-router';

const CrManagerView = () => import('@/views/crManager/crManager.vue');

const CashRegisterIndex = () =>
  import('@/views/crManager/cashRegisters/index.vue');
const FiscalMemoriesIndex = () =>
  import('@/views/crManager/fiscalMemories/index.vue');

export default {
  path: '/cr-manager',
  component: CrManagerView,
  redirect: '/cr-manager/cash-registers',
  meta: { layout: 'default', public: false },
  children: [
    {
      path: 'cash-registers',
      name: 'cash-registers-index',
      component: CashRegisterIndex,
    },
    {
      path: 'fiscal-memories',
      name: 'fiscal-memories-index',
      component: FiscalMemoriesIndex,
    },
  ],
} as RouteConfig;
