import StoreConstructor from '@/store/core/StoreConstructor';
import { RootStores } from '@/store/core/RootStore';
import { action, observable } from 'mobx';
import { DictionaryEntity } from '@/entities/DictionaryEntity';
import { nsiApi } from '@/api';
import { IPagedDto } from '@/types/IPagedDto';
import { IDictionaryEntityDto } from '@/types/IDictionaryEntityDto';
import { postDictNotificationStatusSearch } from '@/api/endpoints/nsi';

export default class NotificationStatusList extends StoreConstructor {
  @observable items: DictionaryEntity[] = [];
  @observable loading = false;
  @observable totalItems = 0;

  constructor(stores: RootStores) {
    super(stores);
  }

  @action.bound
  public async fetch(): Promise<void> {
    try {
      this.loading = true;
      const response = await nsiApi.post<IPagedDto<IDictionaryEntityDto>>(
        postDictNotificationStatusSearch(),
        { page: { page: 0, size: 10000 } },
      );

      const { data, total } = response.data;
      this.items = data.map((item) => new DictionaryEntity(item));
      this.totalItems = total;
    } catch (e) {
      console.error(e);
      this.items = [];
      this.totalItems = 0;
    } finally {
      this.loading = false;
    }
  }
}
