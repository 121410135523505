export class Sort {
  public prop: string;
  public direction: string | null;

  constructor(prop = '', direction: string | null = null) {
    this.prop = prop;
    this.direction = direction;
  }

  public get sorts(): { field: string; order: string | null }[] | null {
    if (this.isEmpty()) {
      return null;
    }

    return [
      {
        field: this.prop,
        order: this.direction!.toUpperCase(),
      },
    ];
  }

  public isEmpty(): boolean {
    return (
      this.prop == null ||
      this.prop == '' ||
      this.direction == null ||
      this.direction == ''
    );
  }
}
