import { IContentDto, IDictContentType } from '@/types/IContentDto';
import DateTime from '@/entities/DateTime';
import { DictionaryEntity } from '@/entities/DictionaryEntity';
import { MessageEntity } from '@/entities/MessageEntity';
import DictionariesStore from '@/store/dictionaries/dictionaries';

export class DictContentTypeEntity extends DictionaryEntity {
  // media-type (READ-ONLY)
  public mediaType: string;

  // содержит текстовые данные (READ-ONLY)
  public textual: boolean;

  constructor(dto: IDictContentType | null) {
    super(dto);

    this.mediaType = dto == null ? '' : dto.mediaType;
    this.textual = dto == null ? false : dto.textual;
  }
}

export class ContentEntity {
  // Идентификатор записи
  public id: string;

  // Дата и время создания
  public created: DateTime;

  // Дата и время изменения
  public updated: DateTime;

  // Сообщение
  public message: MessageEntity;

  // название файла вложения
  public name: string;

  // Данные содержимого сообщения
  public body: string;

  // Данные содержимого сообщения
  public bodyBin: string;

  // Тип содержимого
  public type: DictContentTypeEntity;

  constructor(dto: IContentDto | null, dictionaries: DictionariesStore) {
    if (dto == null) {
      this.id = '';
      this.created = new DateTime(null);
      this.updated = new DateTime(null);
      this.message = new MessageEntity(null, dictionaries);
      this.name = '';
      this.body = '';
      this.bodyBin = '';
      this.type = new DictContentTypeEntity(null);

      return;
    }

    this.id = dto.id;
    this.created = new DateTime(dto.created);
    this.updated = new DateTime(dto.updated);
    this.message = new MessageEntity(dto.message, dictionaries);
    this.name = dto.name;
    this.body = dto.body;
    this.bodyBin = dto.bodyBin;
    this.type = new DictContentTypeEntity(dto.type);
  }
}
