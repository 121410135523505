import { ApiService } from '@/api/services/core';
import { baseApi, stoWebControlApi } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { getQrParams, IDevicesGroup, IDevicesGroupItemWithStats } from './type';
import { EntityID, IStore } from '@/libs/interfaces';

export class CRService extends ApiService {
  public getList() {
    const params: any = { allStatuses: true };
    return baseApi.get<IDevicesGroup[]>(ENDPOINTS.lk.cashRegister, { params });
  }

  public async getCashRegisters(id: EntityID, allStatuses = false) {
    let url = ENDPOINTS.lk.cashRegister;

    if (allStatuses) {
      url += `?allStatuses=${allStatuses}`;
    }

    return baseApi.get<IStore[]>(url);
  }

  public getListForLocation(locationId: number) {
    return baseApi.get<IDevicesGroupItemWithStats[]>(
      ENDPOINTS.lk.cashRegisterByLocation(locationId),
    );
  }

  public async getCashRegistersStat(params: any) {
    const { from, to, ...rest } = params;

    return baseApi.get<any>(
      ENDPOINTS.lk.cashRegisterStatsEndpoint(from, to),
      rest,
    );
  }

  getAgentCashRegisters(taxpayerId: string | number) {
    return baseApi.get<string[]>(ENDPOINTS.lk.agentCashRegistersEndpoint);
  }

  getAgentCashRegisterQr(params: getQrParams) {
    return stoWebControlApi.post<Blob>(ENDPOINTS.lk.qr, params, {
      responseType: 'blob',
    });
  }
}
