
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { format } from 'date-fns';
import { enUS, ru } from 'date-fns/esm/locale';

@Component({
  name: 'FormattedDate',
})
export default class FormattedDate extends Vue {
  @Prop({
    type: [Date, String],
  })
  date!: Date | string;

  @Prop({
    type: String,
  })
  formatType!: string;

  localesList: { [key: string]: any } = {
    ru: ru,
    en: enUS,
  };

  get currentLocale() {
    return this.localesList[this.$i18n.locale] || ru;
  }

  get formattedDate() {
    return format(new Date(this.date), this.formatType, {
      locale: this.currentLocale,
    });
  }
}
