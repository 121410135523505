import { RouteConfig } from 'vue-router';

const AnalyticsView = () => import('@/views/analytics/analytics.vue');

export default {
  path: '/analytics',
  name: 'analitycs',
  component: AnalyticsView,
  meta: { layout: 'default', public: false },
} as RouteConfig;
