import { ICurrency } from '@/libs/interfaces';
import services from '@/api/services';
import { CurrencyType } from '@/libs/enums';

export abstract class CurrencyCatalogBaseComponent {
  public isLoading = false;
  public isLoaded = false;
  public searchUsed = false;
  public currencyList: ICurrency[] = [];
  public filteredCurrencyList: ICurrency[] = [];
  public currencyType = CurrencyType;

  protected codeOrNameSearch = '';

  public async ngOnInit(): Promise<void> {
    try {
      this.isLoaded = false;
      this.isLoading = true;
      await this.fetchCurrencyList();
      this.filteredCurrencyList = this.currencyList;
    } catch (e) {
      console.error('ngOnInit: ', e);
    } finally {
      this.isLoading = false;
      this.isLoaded = true;
    }
  }

  get hasCurrency(): boolean {
    return this.currencyList != null && this.currencyList.length > 0;
  }

  public searchCurrency(codeOrName: string): void {
    this.codeOrNameSearch = codeOrName;
    this.searchUsed = codeOrName != null && codeOrName.trim() !== '';
    this.filteredCurrencyList = this.searchUsed
      ? [
          ...this.currencyList.filter(
            (currency: ICurrency) =>
              (currency.code != null &&
                currency.code
                  .toUpperCase()
                  .includes(codeOrName.toUpperCase())) ||
              (currency.name != null &&
                currency.name.toUpperCase().includes(codeOrName.toUpperCase())),
          ),
        ]
      : [...this.currencyList];
  }

  public addCurrency(currencyType: CurrencyType): void {
    if (
      this.currencyList.some(
        (currency: ICurrency) => currency.id == null || currency.id === 0,
      )
    ) {
      return;
    }

    this.currencyList.forEach(
      (currency: ICurrency) => (currency.isEdit = false),
    );

    const newCurrency: ICurrency = {
      id: 0,
      code: '',
      name: '',
      sellExchangeRate: 0,
      buyExchangeRate: 0,
      amount: 0,
      modifyDate: '',
      isEdit: true,
      type: currencyType,
      hidden: false,
    };

    this.currencyList = [newCurrency, ...this.currencyList];
    this.codeOrNameSearch = '';
    this.searchCurrency(this.codeOrNameSearch);
  }

  public abstract saveCurrency(currency: ICurrency): Promise<void>;
  public abstract deleteCurrency(currency: ICurrency): Promise<void>;

  protected async fetchCurrencyList(): Promise<void> {
    this.currencyList = await services.currencyService.getCurrencyList();
  }
}
