import { RouteConfig } from 'vue-router';
import AuthRoutes from './auth';
import CRManagerRoutes from './cr-manager';
import AnalyticsRoutes from './analytics';
import AnalyticsDetailRoute from './analyticsDetail';
import ServicesRoutes from './services';
import MessagesRoutes from './messages';
import Voucher from '@/views/voucher.vue';

const CrDetailsView = () => import('@/views/crDetails/crDetails.vue');

const CrDetailsIndex = () => import('@/views/crDetails/index.vue');

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: { name: 'cash-registers-index' },
  },
  {
    path: '/voucher/:id',
    name: 'analitycs',
    component: Voucher,
    meta: { layout: 'empty', public: true },
  },
  AuthRoutes,
  CRManagerRoutes,
  AnalyticsRoutes,
  AnalyticsDetailRoute,
  ServicesRoutes,
  MessagesRoutes,
  {
    path: '/cr-manager/cr-details/:crSn/:crId',
    component: CrDetailsView,
    meta: { layout: 'default', public: true },
    children: [
      {
        path: '',
        name: 'cr-details-index',
        component: CrDetailsIndex,
      },
    ],
  },
];

export default routes;
