import {
  IAddress,
  IBoundaryDates,
  IDetailedReceipt,
  IOverallTicketStatistics,
  IReceipt,
  IReceiptStatistics,
} from '@/libs/interfaces';
import {
  BackendReceiptOperationTypes,
  BackendReceiptTypes,
  SyntheticReceiptTypes,
} from '@/libs/enums';

import { sumBy } from 'lodash';

import { format, addDays, endOfMonth } from 'date-fns';

export const createUrl = (basePath: string) => (path: string) =>
  `${basePath}${'/' + path}`;

/**
 *
 * @param number Number
 * @param txt for example Russian: ['Рубль', 'Рубля', 'Рублей', 'Рубль'], English: ['Ruble', 'Rubles', 'Rubles', 'Rubles']
 * @param cases
 */
export const sklonenie = (
  number: number,
  txt: string[],
  cases = [2, 3, 1, 1, 1, 2],
) =>
  txt[
    number === 1
      ? 0
      : number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];

export function calculateDaysLeft(targetDate: Date): number {
  const today = new Date();
  const diffTime = targetDate.getTime() - today.getTime();
  return diffTime > 0 ? Math.ceil(diffTime / (1000 * 3600 * 24)) : 0;
}

export function convertAddressObjectToString(address: IAddress): string {
  if (address && typeof address === 'object') {
    return [
      address.postalCode,
      address.route,
      address.streetNumber,
      address.locality,
      address.country,
    ]
      .filter(Boolean)
      .join(', ');
  }
  return '';
}

export function getSyntheticReceiptType(receipt: {
  type: BackendReceiptTypes;
  operationType: BackendReceiptOperationTypes;
}): SyntheticReceiptTypes | undefined {
  if (receipt == null) throw new Error('receipt is null');

  switch (receipt.type) {
    case BackendReceiptTypes.TICKET:
      if (receipt.operationType === BackendReceiptOperationTypes.INCOME)
        return SyntheticReceiptTypes.INCOME;
      if (receipt.operationType === BackendReceiptOperationTypes.INCOME_RETURN)
        return SyntheticReceiptTypes.INCOME_RETURN;
      if (receipt.operationType === BackendReceiptOperationTypes.EXPENDITURE)
        return SyntheticReceiptTypes.EXPENDITURE;
      if (
        receipt.operationType ===
        BackendReceiptOperationTypes.EXPENDITURE_RETURN
      )
        return SyntheticReceiptTypes.EXPENDITURE_RETURN;
      break;

    case BackendReceiptTypes.CORRECTION_TICKET:
      if (receipt.operationType === BackendReceiptOperationTypes.INCOME)
        return SyntheticReceiptTypes.CORRECTION_INCOME;
      if (receipt.operationType === BackendReceiptOperationTypes.INCOME_RETURN)
        return SyntheticReceiptTypes.CORRECTION_INCOME_RETURN;
      if (receipt.operationType === BackendReceiptOperationTypes.EXPENDITURE)
        return SyntheticReceiptTypes.CORRECTION_EXPENDITURE;
      if (
        receipt.operationType ===
        BackendReceiptOperationTypes.EXPENDITURE_RETURN
      )
        return SyntheticReceiptTypes.CORRECTION_EXPENDITURE_RETURN;
      break;

    default:
      throw new Error(`unsupported receipt type ${receipt.type}`);
  }
}

export function getReceiptType(
  receipt: IReceipt | IDetailedReceipt,
): SyntheticReceiptTypes | undefined {
  if (!receipt) return SyntheticReceiptTypes.INCOME;
  const { operationType, correction } = receipt;

  if (!!correction && typeof correction === 'object') {
    switch (operationType) {
      case 1:
        return SyntheticReceiptTypes.CORRECTION_INCOME;
      case 2:
        return SyntheticReceiptTypes.CORRECTION_INCOME_RETURN;
      case 3:
        return SyntheticReceiptTypes.CORRECTION_EXPENDITURE;
      case 4:
        return SyntheticReceiptTypes.CORRECTION_EXPENDITURE_RETURN;
    }
  } else {
    switch (operationType) {
      case 1:
        return SyntheticReceiptTypes.INCOME;
      case 2:
        return SyntheticReceiptTypes.INCOME_RETURN;
      case 3:
        return SyntheticReceiptTypes.EXPENDITURE;
      case 4:
        return SyntheticReceiptTypes.EXPENDITURE_RETURN;
    }
  }
}

export function getDefaultStatsBetweenDates(
  startDate: string,
  endDate: string,
): { [key: string]: IReceiptStatistics } {
  const dates = getAllDaysBetweenDates(startDate, endDate);
  return dates.reduce((p, c) => {
    return {
      ...p,
      [c]: {
        avgTicket: 0,
        income: 0,
        revenue: 0,
        ticketCorrectionCount: 0,
        ticketIncomeCount: 0,
        ticketRefundCount: 0,
        totalCorrectIncome: 0,
        totalCorrectRefund: 0,
        totalIncome: 0,
        totalRefund: 0,
        totalTicketCount: 0,
        totalVat20: 0,
        vat20Correction: 0,
        vat20Income: 0,
        vat20Refund: 0,
      },
    };
  }, {});
}

export function getAllDaysBetweenDates(
  startDate: string,
  endDate: string,
): string[] {
  const dates = [];
  while (new Date(startDate).getTime() <= new Date(endDate).getTime()) {
    dates.push(startDate);
    startDate = format(addDays(new Date(startDate), 1), 'yyyy-MM-dd');
  }
  return dates;
}
export function getQuarterBoundaryDates(quarter: string, year: string) {
  switch (quarter) {
    case 'Q1':
      return {
        startDate: format(new Date(Number(year), 0, 1), 'yyyy-MM-dd'),
        endDate: format(new Date(Number(year), 2, 31), 'yyyy-MM-dd'),
      };
    case 'Q2':
      return {
        startDate: format(new Date(Number(year), 3, 1), 'yyyy-MM-dd'),
        endDate: format(new Date(Number(year), 5, 30), 'yyyy-MM-dd'),
      };
    case 'Q3':
      return {
        startDate: format(new Date(Number(year), 6, 1), 'yyyy-MM-dd'),
        endDate: format(new Date(Number(year), 8, 30), 'yyyy-MM-dd'),
      };
    case 'Q4':
      return {
        startDate: format(new Date(Number(year), 9, 1), 'yyyy-MM-dd'),
        endDate: format(new Date(Number(year), 11, 31), 'yyyy-MM-dd'),
      };
  }
}

export function getMonthBoundaryDates(
  month: string,
  year: string,
): IBoundaryDates {
  const startDate: string = format(new Date(+year, +month, 1), 'yyyy-MM-dd');
  const endDate: string = format(endOfMonth(new Date(startDate)), 'yyyy-MM-dd');
  return {
    startDate,
    endDate,
  };
}

export function getYearBoundaryDates(year: string): IBoundaryDates {
  return {
    startDate: `${year}-01-01`,
    endDate: `${year}-12-31`,
  };
}

export function getOverallStatistics(statistics: {
  [key: string]: IReceiptStatistics;
}): IOverallTicketStatistics {
  const getGovFee = (
    stats: IReceiptStatistics,
    key: SyntheticReceiptTypes,
  ): number => {
    if (
      stats == null ||
      stats.ticketCounters == null ||
      stats.ticketCounters[key] == null
    ) {
      return 0;
    }

    return stats.ticketCounters[key].totalGovernmentFee;
  };

  return Object.keys(statistics).reduce(
    (p: IOverallTicketStatistics, c: string) => ({
      revenue: Math.round(p.revenue + statistics[c].revenue),
      vat: Math.round(p.vat + sumBy(statistics[c].vat, (i) => i.totalValue)),
      st: Math.round(p.st + sumBy(statistics[c].st, (i) => i.totalValue)),
      receipts: Math.round(
        p.receipts + (statistics[c] as IReceiptStatistics).totalTicketCount,
      ),
      averageReceipt: Math.round(
        p.averageReceipt + (statistics[c] as IReceiptStatistics).avgTicket,
      ),
      totalGovernmentFee: Math.round(
        p.totalGovernmentFee +
          (getGovFee(statistics[c], SyntheticReceiptTypes.INCOME) +
            getGovFee(statistics[c], SyntheticReceiptTypes.INCOME_RETURN)),
      ),
    }),
    {
      revenue: 0,
      vat: 0,
      st: 0,
      receipts: 0,
      averageReceipt: 0,
      totalGovernmentFee: 0,
    } as IOverallTicketStatistics,
  );
}

export const downloadFile = (blob: Blob, name = 'Cash register QR') => {
  const href = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', name); //or any other extension
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const downloadFileFromBuffer = (
  filename: string,
  buffer: any,
  mediaType: string,
): void => {
  const blob = new Blob([buffer], {
    type: mediaType,
  });
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  if (link.download !== undefined) {
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

type NumGroupConfig = {
  incorrectSign?: string;
  fraction?: boolean;
  fractionDigits?: number;
};
const defaultConfig: NumGroupConfig = {
  incorrectSign: '—',
  fraction: true,
  fractionDigits: 2,
};

export function numGroup(
  value: number | null | undefined | '',
  options?: NumGroupConfig,
) {
  const opts = Object.assign(
    { ...defaultConfig },
    options || {},
  ) as Required<NumGroupConfig>;
  if (value === null || value === undefined || value === '')
    return opts.incorrectSign;
  return Number(value.toFixed(2)).toLocaleString(undefined, {
    ...(opts.fraction && {
      minimumFractionDigits: opts.fractionDigits,
      maximumFractionDigits: opts.fractionDigits,
    }),
  });
}
