import { observable, action } from 'mobx';
import { RootStores } from '@/store/core/RootStore';
import StoreConstructor from '@/store/core/StoreConstructor';
import { i18n } from '@/i18n';
import { CurrencyType } from '@/libs/enums';
import { ICurrency } from '@/libs/interfaces';
import format from 'date-fns/format';

import {
  ControlsCollection,
  FormControl,
  FormGroup,
  requiredValidator,
  patternValidator,
  minLengthValidator,
  wrapperSequentialCheck,
  maxLengthValidator,
} from '@quantumart/mobx-form-validation-kit';

interface FormNewItem extends ControlsCollection {
  code: FormControl<string>;
  name: FormControl<string>;
  amount: FormControl<number | null>;
  sell: FormControl<number | null>;
  buy: FormControl<number | null>;
}

export enum EditStatusCurrency {
  EDIT = 'edit',
  CREATE = 'create',
}

const newItemFormBase: ICurrency = {
  code: '',
  name: '',
  amount: null,
  sellExchangeRate: null,
  buyExchangeRate: null,
  isEdit: false,
  type: CurrencyType.Catalog,
  id: 0,
  modifyDate: '',
  hidden: false,
};

export default class CurrencyCatalog extends StoreConstructor {
  @observable newItem: FormGroup<FormNewItem>;

  @observable newItemForm = newItemFormBase;

  @observable isEditCurrencyCatalog = false;
  @observable editTypeCurrencyCatalog: EditStatusCurrency =
    EditStatusCurrency.CREATE;

  createForm = () => {
    return new FormGroup<FormNewItem>({
      code: new FormControl<string>(this.newItemForm.code as string, {
        validators: [
          wrapperSequentialCheck([
            requiredValidator(
              i18n.tc('services.currency-catalog.table.form.code.required'),
            ),
            patternValidator(
              /[a-zA-Zа-яА-Я-\s]/,
              i18n.tc('services.currency-catalog.table.form.code.pattern'),
            ),
            minLengthValidator(
              2,
              i18n.tc('services.currency-catalog.table.form.code.pattern'),
            ),
            maxLengthValidator(
              3,
              i18n.tc('services.currency-catalog.table.form.code.pattern'),
            ),
          ]),
        ],
      }),
      name: new FormControl<string>(this.newItemForm.name as string, {
        validators: [
          wrapperSequentialCheck([
            requiredValidator(
              i18n.tc('services.currency-catalog.table.form.name.required'),
            ),
          ]),
        ],
      }),
      amount: new FormControl<number | null>(
        this.newItemForm.amount as number,
        {
          validators: [
            wrapperSequentialCheck([
              requiredValidator(
                i18n.tc('services.currency-catalog.table.form.amount.required'),
              ),
            ]),
          ],
        },
      ),
      sell: new FormControl<number | null>(
        this.newItemForm.sellExchangeRate as number,
        {
          validators: [
            wrapperSequentialCheck([
              requiredValidator(
                i18n.tc('services.currency-catalog.table.form.sell.required'),
              ),
            ]),
          ],
        },
      ),
      buy: new FormControl<number | null>(
        this.newItemForm.buyExchangeRate as number,
        {
          validators: [
            wrapperSequentialCheck([
              requiredValidator(
                i18n.tc('services.currency-catalog.table.form.buy.required'),
              ),
            ]),
          ],
        },
      ),
    });
  };

  constructor(stores: RootStores) {
    super(stores);

    this.newItem = this.createForm();
  }

  @action.bound SetEditCurrency = (item: ICurrency) => {
    this.newItemForm = item;

    this.newItem = this.createForm();

    this.SetEditCurrenyCatalog(EditStatusCurrency.EDIT);
  };

  @action.bound SetEditCurrenyCatalog = (type = EditStatusCurrency.CREATE) => {
    this.isEditCurrencyCatalog = true;
    this.editTypeCurrencyCatalog = type;
  };

  @action.bound CloseEditCurrencyCatalog = () => {
    this.isEditCurrencyCatalog = false;
    this.editTypeCurrencyCatalog = EditStatusCurrency.CREATE;
    this.newItemForm = newItemFormBase;
    this.newItem = this.createForm();
  };

  @action.bound SaveCurrency = () => {
    const currency: ICurrency = {
      isEdit: this.newItemForm.isEdit,
      type: this.newItemForm.type,
      id: this.newItemForm.id,
      code: this.stores.currencyCatalog.newItem.controls.code.value,
      name: this.stores.currencyCatalog.newItem.controls.name.value,
      amount: this.stores.currencyCatalog.newItem.controls.amount.value || 0,
      sellExchangeRate:
        this.stores.currencyCatalog.newItem.controls.sell.value || 0,
      buyExchangeRate:
        this.stores.currencyCatalog.newItem.controls.buy.value || 0,

      hidden: this.newItemForm.hidden,
      modifyDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
    };

    this.stores.currencyStore.saveCurrency(currency);
    this.CloseEditCurrencyCatalog();
  };
}
