import { action, computed, observable } from 'mobx';
import StoreConstructor from '@/store/core/StoreConstructor';

export type Sort = {
  prop: string;
  direction: string | null;
};

export default abstract class ListStore<T> extends StoreConstructor {
  withKeywordSort: string[] = ['acceptStatus', 'status', 'docType'];
  withTextSort: string[] = [];

  @observable items: T[] = [];
  @observable loading = false;
  @observable limit = 20;
  @observable page = 0;
  @observable pageSize = 20;
  @observable sort: Sort = {
    prop: '',
    direction: null,
  };
  @observable search = '';
  @observable filters = {};
  @observable totalItems = 0;

  @computed get maxPage() {
    return Math.max(0, Math.ceil(this.totalItems / this.pageSize) - 1);
  }

  @computed get computedSort() {
    let prop = this.sort.prop;
    if (this.withKeywordSort.includes(prop)) prop = prop + '.keyword';
    if (this.withTextSort.includes(prop)) prop = prop + '.text';

    return {
      prop,
      direction: this.sort.direction,
    };
  }

  @action.bound async setPage(page: number) {
    this.page = page;
    await this.fetch();
  }

  @action.bound async setPageSize(size: number | null) {
    if (!size) return;
    this.pageSize = size;
    this.page = 0;
    await this.fetch();
  }

  @action.bound async setSort(sort: Sort) {
    this.sort = sort;
    this.page = 0;
    await this.fetch();
  }

  @action.bound async setLimit(limit: number) {
    this.limit = limit;
    this.page = 1;
    await this.fetch();
  }

  @action.bound async refresh() {
    await this.fetch();
  }

  @action.bound
  async fetch(params?: any) {
    this.loading = true;
    try {
      const { items, totalItems } = await this.fetchData(params);
      this.items = items || [];
      this.totalItems = totalItems || 0;
    } catch (e) {
      console.error(e);
      this.items = [];
      this.totalItems = 0;
    } finally {
      this.loading = false;
    }
  }

  fetchData(...args: any): Promise<any> {
    throw new Error('Need to override the Fetch method');
  }
}
