import { ApiService } from '@/api/services/core';
import { baseApi } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { format } from 'date-fns';

import { AnalyticsDateFilterTypes, VoucherListCRsType } from '@/libs/enums';
import {
  getDefaultStatsBetweenDates,
  getQuarterBoundaryDates,
  getMonthBoundaryDates,
  getYearBoundaryDates,
  getOverallStatistics,
} from '@/utils';
import {
  ITicketStatisticsApiResponse,
  IBoundaryDates,
} from '@/libs/interfaces';

export class StatsService extends ApiService {
  private getBoundaryDates(options: any): {
    startDate: string;
    endDate: string;
  } {
    const {
      dateFilterType = AnalyticsDateFilterTypes.YEAR,
      quarter,
      year,
      month,
    } = options;
    switch (dateFilterType) {
      case AnalyticsDateFilterTypes.QUARTER:
        return getQuarterBoundaryDates(quarter, year)!;
      case AnalyticsDateFilterTypes.MONTH:
        return getMonthBoundaryDates(month, year);
      default:
        return getYearBoundaryDates(year);
    }
  }

  private getStatisticsFilter(
    taxpayerId: string,
    options: any,
    type: VoucherListCRsType,
  ) {
    const boundaryDates = this.getBoundaryDates(options);
    const ids = (options.kktList || []).map((n: any) => n.toString());

    return {
      lpId: taxpayerId,
      regNumbers: ids,
      beginDate: boundaryDates.startDate,
      endDate: boundaryDates.endDate,
      ticketsSearchType: type,
    };
  }

  public async getStatistics(
    taxpayerId: string,
    options: any,
    type: VoucherListCRsType,
  ) {
    const filter = this.getStatisticsFilter(taxpayerId, options, type);
    return baseApi
      .post<ITicketStatisticsApiResponse>(ENDPOINTS.stats.grouped, filter)
      .then(({ data }: { data: ITicketStatisticsApiResponse }) => {
        if (!data) {
          return {
            dailyStatistics: {},
            overallStatistics: {
              revenue: 0,
              vat: 0,
              st: 0,
              receipts: 0,
              averageReceipt: 0,
              totalGovernmentFee: 0,
            },
            originalDailyStatisticsCount: 0,
            count: 0,
            kktIds: [],
          };
        }
        const { dailyStats = {}, ids: kktIds = [], count = 0 } = data;
        const { beginDate, endDate } = filter;
        const dailyStatistics = {
          ...getDefaultStatsBetweenDates(beginDate, endDate),
          ...dailyStats,
        };
        const overallStatistics = getOverallStatistics(dailyStatistics);

        return {
          count,
          kktIds,
          dailyStatistics,
          overallStatistics,
          originalDailyStatisticsCount: Object.keys(dailyStats).length,
        };
      });
  }

  public getStatFilter(taxpayerId: string, options: any, isAgents: boolean) {
    const {
      dateFilterType = AnalyticsDateFilterTypes.YEAR,
      quarter,
      year,
      month,
      kktList,
    } = options;
    let url = `${ENDPOINTS.stats.common}/${taxpayerId}/groupDate`;
    let boundaryDates: IBoundaryDates;
    switch (dateFilterType) {
      case AnalyticsDateFilterTypes.QUARTER:
        url = `${url}/year/${year}/quarter/${quarter}`;
        boundaryDates = getQuarterBoundaryDates(quarter, year)!;
        break;
      case AnalyticsDateFilterTypes.MONTH:
        {
          const m = format(new Date(Number(year), Number(month)), 'yyyy-MM');
          url = `${url}/yearmonth/${m}`;
          boundaryDates = getMonthBoundaryDates(month, year);
        }

        break;
      default:
        url = `${url}/year/${year}`;
        boundaryDates = getYearBoundaryDates(year);
    }
    const ids = (kktList || []).map((n: any) => n.toString());
    const params = { ids, ...(isAgents && { agentCRs: 'true' }) };
    return {
      url,
      params,
      boundaryDates,
    };
  }

  public getStatSummary(taxpayerId: string, options: any, isAgents: boolean) {
    const { url, params, boundaryDates } = this.getStatFilter(
      taxpayerId,
      options,
      isAgents,
    );
    return baseApi
      .get<ITicketStatisticsApiResponse>(url, {
        params,
        paramsSerializer: {
          indexes: null,
        },
      })
      .then(({ data }: { data: ITicketStatisticsApiResponse }) => {
        if (!data) {
          return {
            dailyStatistics: {},
            overallStatistics: {
              revenue: 0,
              vat: 0,
              st: 0,
              receipts: 0,
              averageReceipt: 0,
              totalGovernmentFee: 0,
            },
            originalDailyStatisticsCount: 0,
            count: 0,
            kktIds: [],
          };
        }

        const { dailyStats = {}, ids: kktIds = [], count = 0 } = data;
        const { startDate, endDate } = boundaryDates;
        const dailyStatistics = {
          ...getDefaultStatsBetweenDates(startDate, endDate),
          ...dailyStats,
        };
        const overallStatistics = getOverallStatistics(dailyStatistics);

        return {
          count,
          kktIds,
          dailyStatistics,
          overallStatistics,
          originalDailyStatisticsCount: Object.keys(dailyStats).length,
        };
      });
  }
}
