import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes';
import stores from '@/store/core/RootStore';
import Component from 'vue-class-component';

Vue.use(VueRouter);

Component.registerHooks(['beforeRouteEnter']);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const auth = localStorage.getItem('auth');
  if (!to.meta?.public && (!auth || !JSON.parse(auth))) {
    next({ name: 'auth-login-index' });
  } else next();
});

router.beforeEach(async (to, from, next) => {
  if (to.query.key) {
    localStorage.setItem('demoKey', to.query.key as string);
    stores.user.demoKey = localStorage.getItem('demoKey');

    if (!stores.user.demoKey && to.name !== 'auth-login-index') {
      next({ name: 'auth-login-index' });
    } else {
      if (stores.user.demoKey && to.name === 'auth-login-index') {
        const credentials = JSON.parse(atob(stores.user.demoKey));

        stores.user.LOGIN(credentials).then(() => {
          setTimeout(() => {
            next({
              path: '/',
            });
          }, 1500);
        });
      }
      next();
    }
  } else {
    const key = localStorage.getItem('demoKey');

    if (key && to.name === 'auth-login-index') {
      const credentials = JSON.parse(atob(stores.user.demoKey));

      stores.user.LOGIN(credentials).then(() => {
        next({
          path: '/',
        });
      });
    }

    next();
  }
});

export default router;
