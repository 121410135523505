import { createUrl } from '@/utils';

const open = createUrl('open');

const taxRates = open('tax-rates');
const taxSystem = open('tax-systems');
const businessActivities = open('business-activities');
const entrepreneurshipObjects = open('entrepreneurship-objects');
const taxAuthorityDepartments = open('tax-authority-departments');
const legalPersonActivities = open('legal-person-activities');
const formatAndLogicControlErrors = open('fd-error-info');

export const openEndpoints = {
  taxRates,
  taxSystem,
  businessActivities,
  entrepreneurshipObjects,
  taxAuthorityDepartments,
  legalPersonActivities,
  formatAndLogicControlErrors,
};
