import { observable, action } from 'mobx';
import { RootStores } from '@/store/core/RootStore';
import StoreConstructor from '@/store/core/StoreConstructor';
import services from '@/api/services';
import { ITaxRate } from '@/api/services/openService/type';

export default class Settings extends StoreConstructor {
  @observable _taxRates: ITaxRate[] = [];

  constructor(stores: RootStores) {
    super(stores);
  }

  get taxRates(): ITaxRate[] {
    return this._taxRates;
  }

  @action setTaxRates(val: ITaxRate[]) {
    this._taxRates = val;
  }

  @action.bound async getTaxRates() {
    try {
      const { data } = await services.open.getTaxRates();
      this.setTaxRates(data);
    } catch (e: any) {
      console.warn(e);
    }
  }
}
