import { ApiService } from '@/api/services/core';
import { baseApi } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import {
  IFiscalMemoriesParams,
  IFiscalMemoriesResponse,
} from '@/store/fiscalMemories/types';
import { IFNModel } from '@/libs/interfaces';

export class FiscalMemoriesService extends ApiService {
  public getFiscalMemoryList(params: IFiscalMemoriesParams) {
    return baseApi.get<IFiscalMemoriesResponse>(ENDPOINTS.lk.fiscalMemories, {
      ...(params && { params }),
    });
  }

  public addFiscalMemory(fiscalMemory: IFNModel) {
    return baseApi.put(
      ENDPOINTS.lk.addFiscalMemory(
        fiscalMemory.fnNumber,
        fiscalMemory.validationCode,
      ),
      {},
    );
  }
}
