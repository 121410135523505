
import Component from 'vue-class-component';
import Vue from 'vue';

import ReceiptDetails from '@/components/modals/ReceiptDetails.vue';

@Component({
  name: 'Voucher',
  components: { ReceiptDetails },
})
export default class Voucher extends Vue {
  get id() {
    return this.$route.params.id;
  }
}
