import moment from 'moment/moment';
import { i18n } from '@/i18n';

export default class DateTime {
  private readonly _dateTime: string | null;

  constructor(dateTime: string | null) {
    this._dateTime = dateTime;
  }

  public get date(): string {
    return this._dateTime != null ? moment(this._dateTime).format('L') : '';
  }

  public get time(): string {
    return this._dateTime != null ? moment(this._dateTime).format('LT') : '';
  }

  public get dateTime(): string {
    return this._dateTime != null
      ? moment(this._dateTime).format(
          i18n.locale === 'en' ? 'L LT' : 'DD.MM.YYYY HH:mm',
        )
      : '';
  }
}
