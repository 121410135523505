import { IDictionaryEntityDto } from '@/types/IDictionaryEntityDto';
import { IMessageDto } from '@/types/IMessageDto';

export enum ContentTypeCode {
  TEXT = 'TEXT',
  HTML = 'HTML',
  PDF = 'PDF',
}

export interface IDictContentType extends IDictionaryEntityDto {
  // media-type (READ-ONLY)
  mediaType: string;

  // содержит текстовые данные (READ-ONLY)
  textual: boolean;
}

// Содержимое сообщения
export interface IContentDto {
  // Идентификатор записи
  id: string;

  // Дата и время создания
  created: string;

  // Дата и время изменения
  updated: string;

  // Сообщение
  message: IMessageDto;

  // название файла вложения
  name: string;

  // Данные содержимого сообщения
  body: string;

  // Данные содержимого сообщения
  bodyBin: string;

  // Тип содержимого
  type: IDictContentType;
}
