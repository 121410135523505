import { observable, action } from 'mobx';
import { RootStores } from '@/store/core/RootStore';
import services from '@/api/services';
import { CurrencyCatalogBaseComponent } from '@/store/currency/baseCurrencyCatalog';
import { ICurrency } from '@/libs/interfaces';
import { CURRENCY_FRACTURE_PART } from '@/libs/constants';

export default class CurrencyStore extends CurrencyCatalogBaseComponent {
  @observable stores: RootStores;

  public isUploading = false;
  public uploadSuccess = false;
  public showUploadResultMessage = false;
  public uploadedFileName = '';

  constructor(stores: RootStores) {
    super();
    this.stores = stores;
  }

  public async saveCurrency(currency: ICurrency): Promise<void> {
    try {
      this.isLoading = true;
      const data = {
        ...currency,
        sellExchangeRate:
          Number(String(currency.sellExchangeRate!).replaceAll(',', '.')) /
          CURRENCY_FRACTURE_PART,
        buyExchangeRate:
          Number(String(currency.buyExchangeRate!).replaceAll(',', '.')) /
          CURRENCY_FRACTURE_PART,
      };
      if (currency.id < 1) {
        await services.currencyService.createCurrency(data);
      } else {
        await services.currencyService.updateCurrency(data);
      }

      await this.fetchCurrencyList();
      this.searchCurrency(this.codeOrNameSearch);
    } catch (e) {
      console.error('saveCurrency: ' + e);
    } finally {
      this.isLoading = false;
    }
  }

  public async deleteCurrency(currency: ICurrency): Promise<void> {
    try {
      this.isLoading = true;
      if (currency.id != null && currency.id !== 0) {
        await services.currencyService.deleteCurrency(currency);

        await this.fetchCurrencyList();
        this.searchCurrency(this.codeOrNameSearch);
      } else {
        const index = this.currencyList.findIndex(
          (cur: ICurrency) => cur.id == null,
        );
        if (index > -1) {
          this.currencyList.splice(index, 1);
          this.searchCurrency(this.codeOrNameSearch);
        }
      }
    } catch (e) {
      console.error('deleteCurrency: ' + e);
    } finally {
      this.isLoading = false;
    }
  }

  public uploadCurrencyFile = async (event: Event) => {
    try {
      this.isUploading = true;
      this.showUploadResultMessage = false;
      const files = (event.target as HTMLInputElement).files;
      if (files?.length) {
        const file = files[0];

        this.uploadedFileName = file.name;
        await services.currencyService.uploadCurrencyFile(file);
        this.isUploading = false;
        this.uploadSuccess = true;
      } else {
        return;
      }

      this.isLoading = true;
      await this.fetchCurrencyList();
      this.codeOrNameSearch = '';
      this.searchCurrency(this.codeOrNameSearch);
      this.isLoading = false;
    } catch (e) {
      this.uploadSuccess = false;
      console.error('uploadCurrencyFile: ', e);
    } finally {
      this.showUploadResultMessage = true;
      setTimeout(() => (this.showUploadResultMessage = false), 3000);
      this.isUploading = false;
      this.isLoading = false;
    }
  };
}
