import moment from 'moment/moment';

export default class TaxPeriod {
  private readonly _taxPeriod: number;

  // Формат: YYYYMM
  constructor(taxPeriod: number) {
    this._taxPeriod = taxPeriod;
  }

  public get year(): number {
    return Number(String(this._taxPeriod).substring(0, 4));
  }

  public get month(): number {
    return Number(String(this._taxPeriod).substring(4, 6));
  }

  public get rawValue(): number {
    return this._taxPeriod;
  }

  public get taxPeriod(): string {
    const monthLowerCased = moment(this._taxPeriod, 'YYYYMM')
      .format('MMMM')
      .toLowerCase();

    const month =
      monthLowerCased.charAt(0).toUpperCase() + monthLowerCased.slice(1);
    const year = moment(this._taxPeriod, 'YYYYMM').format('YYYY');

    return `${month} ${year}`;
  }

  public static fromDate(date: Date | null): string {
    return moment(date).format('YYYYMM');
  }

  public static fromDateString(date: string | null): string {
    return moment(date, 'YYYY-MM-DD').format('YYYYMM');
  }

  public static createTaxPeriodFromNumbers(
    year: number,
    month: number,
  ): TaxPeriod {
    const taxPeriod = Number(String(year) + String(month).padStart(2, '0'));
    return new TaxPeriod(taxPeriod);
  }

  public static createTaxPeriodFromDate(date: Date): TaxPeriod {
    return new TaxPeriod(Number(TaxPeriod.fromDate(date)));
  }
}
