import { authEndpoints as auth } from '@/api/endpoints/auth';
import { openEndpoints as open } from '@/api/endpoints/open';
import { lkEndpoints as lk } from '@/api/endpoints/lk';
import { notificationsEndpoints as notifications } from '@/api/endpoints/notifications';
import { currencyEndpoints as currency } from '@/api/endpoints/currency';
import { userEndpoints as user } from '@/api/endpoints/user';
import { infoEndpoints as info } from '@/api/endpoints/info';
import { crDetailsEndpoints as crDetails } from '@/api/endpoints/crDetails';
import { companyEndpoints as company } from '@/api/endpoints/company';
import { receiptsEndpoints as receipts } from '@/api/endpoints/receipts';
import { statsEndpoints as stats } from '@/api/endpoints/stats';

export const ENDPOINTS = {
  auth,
  open,
  lk,
  notifications,
  currency,
  user,
  info,
  crDetails,
  company,
  receipts,
  stats,
};
