import {
  IJobExecutionDto,
  IJobExecutionResultDto,
  JobExecutionStatusCode,
} from '@/types/IJobExecutionDto';
import LocalizedJson from '@/entities/LocalizedJson';
import Entity from '@/entities/Entity';
import { DictionaryEntity } from '@/entities/DictionaryEntity';
import JobInfoEntity from '@/entities/JobInfoEntity';

export default class JobExecutionEntity extends Entity {
  // Задача
  public jobInfo: JobInfoEntity;

  // Статус исполнения задачи
  public status: DictionaryEntity;

  public flow: any;

  // сообщение об ошибке
  public error: string;

  public parent: any;

  // Результат запуска задачи (READ-ONLY)
  params: LocalizedJson;

  // Тип задачи
  jobType: DictionaryEntity;

  // Результат запуска задачи (???)
  result: JobExecutionResult;

  constructor(dto: IJobExecutionDto) {
    super(dto);

    this.jobInfo = new JobInfoEntity(dto.jobInfo);
    this.status = new DictionaryEntity(dto.status);
    this.flow = dto.flow;
    this.error = dto.error;
    this.parent = dto.parent;
    this.params = new LocalizedJson(dto.params);
    this.jobType = new DictionaryEntity(dto.jobType);
    this.result = new JobExecutionResult(dto.result);
  }

  private getStatusCodeColorStyle(): string {
    if (
      this.status == null ||
      this.status.code == null ||
      this.status.code == ''
    ) {
      return 'neutral';
    }

    switch (this.status.code) {
      case JobExecutionStatusCode.FINISHED:
        return 'success';
      case JobExecutionStatusCode.RUNNING:
        return 'warning';
      case JobExecutionStatusCode.FAILED:
        return 'danger';
      default:
        return 'neutral';
    }
  }
}

export class JobExecutionResult {
  public __flowResult: number;
  public violationsClosed: number;
  public violationsOpened: number;

  constructor(dto: IJobExecutionResultDto | null) {
    if (dto == null) {
      this.__flowResult = 0;
      this.violationsClosed = 0;
      this.violationsOpened = 0;

      return;
    }

    this.__flowResult = dto.__flowResult || 0;
    this.violationsClosed = dto.violationsClosed || 0;
    this.violationsOpened = dto.violationsOpened || 0;
  }
}
