import { observable, action } from 'mobx';
import { RootStores } from '@/store/core/RootStore';
import StoreConstructor from '@/store/core/StoreConstructor';
import services from '@/api/services';
import { ITaxSystem } from '@/libs/interfaces';

export default class TaxSystemStore extends StoreConstructor {
  @observable private _taxSystems: ITaxSystem[] = [];

  constructor(stores: RootStores) {
    super(stores);
  }

  get taxSystems(): ITaxSystem[] {
    return this._taxSystems;
  }

  formattedTaxSystem(userTaxSystems: number[]): string {
    let taxNames = '';
    for (let i = 0; i < userTaxSystems.length; i++) {
      const taxSystem = this.taxSystems.find(
        (t) => t.code === userTaxSystems[i],
      );
      taxNames +=
        (taxSystem ? taxSystem.taxName : '') +
        (i === userTaxSystems.length - 1 ? '' : ', ');
    }

    return taxNames;
  }

  async refreshTaxSystems() {
    try {
      const { data } = await services.open.refreshTaxSystems();
      this._taxSystems = data.sort(
        (a: ITaxSystem, b: ITaxSystem) => a.code - b.code,
      );
    } catch (err) {
      console.error(err);
    }
  }
}
