import { observable, action } from 'mobx';
import { RootStores } from '@/store/core/RootStore';
import StoreConstructor from '@/store/core/StoreConstructor';
import { IReceipt, IShift } from '@/libs/interfaces';
import { getReceiptType } from '@/utils';
import { SyntheticReceiptTypes, ShiftReportTypes } from '@/libs/enums';
import { i18n } from '@/i18n';

export default class CRDetails extends StoreConstructor {
  @observable selectedReceiptId = '';
  @observable receiptType = '';
  @observable selectedReportId = '';
  @observable selectedReportType: ShiftReportTypes = ShiftReportTypes.X;
  @observable titleReport = '';

  constructor(stores: RootStores) {
    super(stores);
  }

  get titleReceiptModal() {
    switch (this.receiptType) {
      case SyntheticReceiptTypes.INCOME:
        return i18n.tc('receipt-title.receiptHeaderIncome');
      case SyntheticReceiptTypes.INCOME_RETURN:
        return i18n.tc('receipt-title.receiptHeaderIncomeReturn');
      case SyntheticReceiptTypes.EXPENDITURE:
        return i18n.tc('receipt-title.receiptHeaderExpense');
      case SyntheticReceiptTypes.EXPENDITURE_RETURN:
        return i18n.tc('receipt-title.receiptHeaderExpenseReturn');
      case SyntheticReceiptTypes.CORRECTION_INCOME:
        return i18n.tc('receipt-title.receiptHeaderCorrectionIncome');
      case SyntheticReceiptTypes.CORRECTION_INCOME_RETURN:
        return i18n.tc('receipt-title.receiptHeaderCorrectionIncomeReturn');
      case SyntheticReceiptTypes.CORRECTION_EXPENDITURE:
        return i18n.tc('receipt-title.receiptHeaderCorrectionExpense');
      case SyntheticReceiptTypes.CORRECTION_EXPENDITURE_RETURN:
        return i18n.tc('receipt-title.receiptHeaderCorrectionExpenseReturn');
    }
  }

  @action.bound openDetailedReceipt(receipt: IReceipt): void {
    this.selectedReceiptId = receipt.id;
    this.receiptType = getReceiptType(receipt) || '';
  }

  @action.bound closeDetailedReceipt(): void {
    this.selectedReceiptId = '';
    this.receiptType = '';
  }

  @action.bound openReport(event: {
    shift: IShift;
    type: ShiftReportTypes;
  }): void {
    this.titleReport = i18n.tc(
      `components.shift-report.${
        event.type === ShiftReportTypes.X
          ? 'x-title'
          : event.type === ShiftReportTypes.Z
          ? 'z-title'
          : 'opening-title'
      }`,
    );
    this.selectedReportId = event.shift.id;
    this.selectedReportType = event.type;
  }

  @action.bound clearSelectedReport(): void {
    this.selectedReportId = '';
    this.selectedReportType = ShiftReportTypes.X;
  }
}
