import { IEntityDto } from '@/types/IEntityDto';
import LocalizedJson from '@/entities/LocalizedJson';
import DateTime from '@/entities/DateTime';

export default class Entity {
  public id: string;
  public created: DateTime;
  public updated: DateTime;
  public title: LocalizedJson;
  public code: string;

  constructor(dto: IEntityDto | null) {
    if (dto == null) {
      this.id = '';
      this.created = new DateTime(null);
      this.updated = new DateTime(null);
      this.title = new LocalizedJson(null);
      this.code = '';

      return;
    }

    this.id = dto.id;
    this.created = new DateTime(dto.created);
    this.updated = new DateTime(dto.updated);
    this.title = new LocalizedJson(dto.title);
    this.code = dto.code;
  }
}
