import { ApiService } from '@/api/services/core';
import { baseApi } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { IMappedDeviceDetails } from '@/views/crDetails/interfaces';
import { calculateDaysLeft } from '@/utils';
import {
  ICrRegHistoryRecord,
  IReceipt,
  IShift,
  IXReport,
  IZReport,
  XReportModel,
  ZReportModel,
} from '@/libs/interfaces';
import { FdErrorFilter } from '@/libs/enums';
import { isEmpty, flattenDeep } from 'lodash';

export class CrDetailsService extends ApiService {
  public async getDetails(id: number) {
    return baseApi
      .get<IMappedDeviceDetails>(ENDPOINTS.crDetails.cashRegisterId(id))
      .then(({ data }: { data: IMappedDeviceDetails }) => {
        const fnActivationDaysLeft = data.fnExpirationDate
          ? calculateDaysLeft(new Date(data.fnExpirationDate))
          : null;
        return {
          ...data,
          fnActivationDaysLeft,
        };
      });
  }

  public getReportByRegNumber(regNumber: string) {
    return baseApi
      .get<ICrRegHistoryRecord[]>(ENDPOINTS.crDetails.regNumber(regNumber))
      .then(({ data }) => {
        if (!data) {
          return [];
        }

        return data
          .map((x) => CrDetailsService.mapCrRegHistoryRecord(x))
          .sort(
            (a, b) =>
              new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime(),
          );
      });
  }

  public getVoucherByShift(shiftId: string, fds: number[]) {
    const body = {
      fds: fds,
      errorsFilter: FdErrorFilter.VALID,
    };

    return baseApi.post<IReceipt[]>(ENDPOINTS.crDetails.shift(shiftId), body);
  }

  public getShifts(params: any) {
    return baseApi.get<IShift[]>(ENDPOINTS.crDetails.shifts, { params });
  }

  public getXReport(shiftId: string, params: any) {
    return baseApi.get(ENDPOINTS.crDetails.xreport(shiftId), {
      params,
    });
  }

  public getOpeningReport(shiftId: string, params: any) {
    return baseApi.get(ENDPOINTS.crDetails.openreport(shiftId), {
      params,
    });
  }

  public getZReport(shiftId: string, params: any) {
    return baseApi.get(ENDPOINTS.crDetails.zreport(shiftId), {
      params,
    });
  }

  private static mapCrRegHistoryRecord(restModel: any): ICrRegHistoryRecord {
    const tags = restModel.tags;

    const taxSystemBitmask = tags
      ?.filter((t: any) => t.tag === 1062 || t.tag === 1032)
      .filter((t: any) => t.tagValue != null)
      .map((t: any) => JSON.parse(t.tagValue));

    const calcItemAttrBitmask = tags
      ?.filter((t: any) => t.tag === 1058 || t.tag === 1066)
      .filter((t: any) => t.tagValue != null)
      .map((t: any) => JSON.parse(t.tagValue));

    const changeReasonBitmask = tags?.find(
      (t: any) => t.tag === 1205,
    )?.tagValue;

    const entrepreneurshipObject = tags?.find(
      (t: any) => t.tag === 1027,
    )?.tagValue;
    const businessActivity = tags?.find((t: any) => t.tag === 1028)?.tagValue;
    const taxAuthorityDepartment = tags?.find(
      (t: any) => t.tag === 1029,
    )?.tagValue;

    return {
      type: restModel.type,
      dateTime: tags?.find((t: any) => t.tag === 1012)?.tagValue,
      purchaseAddress: tags?.find((t: any) => t.tag === 1009)?.tagValue,
      purchasePlace: tags?.find((t: any) => t.tag === 1187)?.tagValue,
      counters: tags?.find((t: any) => t.tag === 1157)?.subtags,
      taxSystems: isEmpty(taxSystemBitmask)
        ? null
        : flattenDeep(taxSystemBitmask),
      calcItemAttributes: isEmpty(calcItemAttrBitmask)
        ? null
        : flattenDeep(calcItemAttrBitmask),
      changeReason: changeReasonBitmask
        ? JSON.parse(changeReasonBitmask)
        : null,
      vatPayer: !!Number(tags?.find((t: any) => t.tag === 1125)?.tagValue),
      entrepreneurshipObject: entrepreneurshipObject
        ? Number(entrepreneurshipObject)
        : null,
      businessActivity: businessActivity ? Number(businessActivity) : null,
      taxAuthorityDepartment: taxAuthorityDepartment
        ? Number(taxAuthorityDepartment)
        : null,
    } as ICrRegHistoryRecord;
  }
}
