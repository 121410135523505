import { RootStores } from '@/store/core/RootStore';
import StoreConstructor from '@/store/core/StoreConstructor';

import { IFormatAndLogicControlError, IReceipt } from '@/libs/interfaces';
import {
  BackendReceiptOperationTypes,
  BackendReceiptTypes,
  FormatAndLogicControlErrorsSeverityType,
  SyntheticReceiptTypes,
} from '@/libs/enums';
import { getSyntheticReceiptType } from '@/utils';

export default class ReceiptGridStore extends StoreConstructor {
  constructor(stores: RootStores) {
    super(stores);
  }

  public extendReceiptData(receipt: IReceipt): any {
    const operationAndReceiptTypesClassesAndCaptions =
      this.getOperationAndReceiptTypesClassesAndCaptions(receipt);
    const errorsClassesAndCaptions = this.getErrorsClassesAndCaptions(receipt);
    receipt.ticketTotalSum = this.checkNegativeCashFlow(receipt);

    return {
      ...receipt,
      ...operationAndReceiptTypesClassesAndCaptions,
      ...errorsClassesAndCaptions,
    };
  }

  public extendReceiptErrorsData(receipt: IReceipt): any {
    const errorsClassesAndCaptions = this.getErrorsClassesAndCaptions(receipt);
    return {
      ...receipt,
      ...errorsClassesAndCaptions,
    };
  }

  public getOperationAndReceiptTypesClassesAndCaptions(receipt: {
    type: BackendReceiptTypes;
    operationType: BackendReceiptOperationTypes;
  }): any {
    const syntheticReceiptType: SyntheticReceiptTypes =
      getSyntheticReceiptType(receipt)!;

    switch (syntheticReceiptType) {
      case SyntheticReceiptTypes.INCOME:
        return {
          iconClass: 'ticket-income',
          operationTypeCaption: 'income',
          receiptType: 'receipt',
          receiptOperationType: 'income',
        };

      case SyntheticReceiptTypes.INCOME_RETURN:
        return {
          iconClass: 'ticket-return',
          operationTypeCaption: 'return',
          receiptType: 'receipt',
          receiptOperationType: 'return',
        };

      case SyntheticReceiptTypes.EXPENDITURE:
        return {
          iconClass: 'ticket-expense',
          operationTypeCaption: 'expenditure',
          receiptType: 'receipt',
          receiptOperationType: 'expenditure',
        };

      case SyntheticReceiptTypes.EXPENDITURE_RETURN:
        return {
          iconClass: 'ticket-expense-return',
          operationTypeCaption: 'expenditure_return',
          receiptType: 'receipt',
          receiptOperationType: 'expenditure_return',
        };

      case SyntheticReceiptTypes.CORRECTION_INCOME:
        return {
          iconClass: 'adjustment-income',
          operationTypeCaption: 'income',
          receiptType: 'adjustment',
          receiptOperationType: 'income',
        };

      case SyntheticReceiptTypes.CORRECTION_INCOME_RETURN:
        return {
          iconClass: 'adjustment-return',
          operationTypeCaption: 'return',
          receiptType: 'adjustment',
          receiptOperationType: 'return',
        };

      case SyntheticReceiptTypes.CORRECTION_EXPENDITURE:
        return {
          iconClass: 'adjustment-expense',
          operationTypeCaption: 'expenditure',
          receiptType: 'adjustment',
          receiptOperationType: 'expenditure',
        };

      case SyntheticReceiptTypes.CORRECTION_EXPENDITURE_RETURN:
        return {
          iconClass: 'adjustment-expense-return',
          operationTypeCaption: 'expenditure_return',
          receiptType: 'adjustment',
          receiptOperationType: 'expenditure_return',
        };

      default:
        throw new Error(
          `unexpected SyntheticReceiptTypes value ${syntheticReceiptType}`,
        );
    }
  }

  private getErrorsClassesAndCaptions(receipt: IReceipt): any {
    const hasErrors: boolean = receipt.errors != null;
    if (!hasErrors) {
      return {
        hasErrors: hasErrors,
        errorIcon: null,
        errorTooltipCaption: null,
        errorCodes: null,
      };
    }

    const errorCodes = Object.keys(receipt.errors)
      .map((errorCode: string) => errorCode.replace('error_', ''))
      .map((errorCode: string) => Number(errorCode));

    const errors =
      this.stores.businessObjectsDictionaryStore.formatAndLogicControlErrors.filter(
        (error: IFormatAndLogicControlError) => errorCodes.includes(error.code),
      );

    const hasCriticalErrors: boolean = errors.some(
      (error: IFormatAndLogicControlError) =>
        error.type === FormatAndLogicControlErrorsSeverityType.CRITICAL,
    );

    const icon: string = hasCriticalErrors ? 'information' : 'attention';

    const tooltipCaption: string = hasCriticalErrors
      ? 'critical-errors'
      : 'receipt-has-errors';

    const codes: number[] = hasCriticalErrors
      ? errors
          .filter(
            (error: IFormatAndLogicControlError) =>
              error.type === FormatAndLogicControlErrorsSeverityType.CRITICAL,
          )
          .map((error: IFormatAndLogicControlError) => error.code)
      : errors
          .filter(
            (error: IFormatAndLogicControlError) =>
              error.type === FormatAndLogicControlErrorsSeverityType.ACCEPTABLE,
          )
          .map((error: IFormatAndLogicControlError) => error.code);

    return {
      hasErrors: hasErrors,
      errorIcon: icon,
      errorTooltipCaption: tooltipCaption,
      errorCodes: codes.join(', '),
    };
  }

  private checkNegativeCashFlow(receipt: IReceipt): number {
    return receipt.operationType ===
      BackendReceiptOperationTypes.INCOME_RETURN ||
      receipt.operationType === BackendReceiptOperationTypes.EXPENDITURE
      ? -1 * receipt.ticketTotalSum
      : receipt.ticketTotalSum;
  }
}
