import { IReportModel, IShiftReport } from './index';
import { ITaxCounter } from '../index';

export interface IXReportSummaryCounters {
  operationTicketsAmount: number;
  operationPostPaymentSum: number;
  operationPrePaymentSum: number;
  totalCashlessSum: number;
  totalCashSum: number;
  totalSum: number;
  taxCounters: ITaxCounter[];
}

export interface IXReport extends IShiftReport {
  produceDateTime: Date;
  shiftSummaryCounters: {
    totalTicketsAmount: number;
    correctionCounters: {
      correctionTicketsAmount: number;
      income: IXReportSummaryCounters;
      incomeReturn: IXReportSummaryCounters;
      expenditure: IXReportSummaryCounters;
      expenditureReturn: IXReportSummaryCounters;
    };
    income: IXReportSummaryCounters;
    incomeReturn: IXReportSummaryCounters;
    expenditure: IXReportSummaryCounters;
    expenditureReturn: IXReportSummaryCounters;
  };
}

export class XReportModel implements IReportModel {
  data: IXReport;

  constructor(data: IXReport) {
    this.data = data;
  }

  get cashTotalSum(): number {
    return (
      this.incomes.totalSum +
      this.returns.totalSum +
      this.expenses.totalSum +
      this.expenseReturns.totalSum +
      this.corrections.incomes.totalSum +
      this.corrections.returns.totalSum +
      this.corrections.expenses.totalSum +
      this.corrections.expenseReturns.totalSum
    );
  }

  get incomes(): IXReportSummaryCounters {
    return this.data?.shiftSummaryCounters?.income || {};
  }

  get returns(): IXReportSummaryCounters {
    return this.data?.shiftSummaryCounters.incomeReturn || {};
  }

  get expenses(): IXReportSummaryCounters {
    return this.data?.shiftSummaryCounters?.expenditure || {};
  }

  get expenseReturns(): IXReportSummaryCounters {
    return this.data?.shiftSummaryCounters?.expenditureReturn || {};
  }

  get corrections() {
    return {
      incomes: this.data.shiftSummaryCounters.correctionCounters.income,
      returns: this.data.shiftSummaryCounters.correctionCounters.incomeReturn,
      expenses: this.data.shiftSummaryCounters.correctionCounters.expenditure,
      expenseReturns:
        this.data.shiftSummaryCounters.correctionCounters.expenditureReturn,
    };
  }
}
