import Vue from 'vue';
import App from './App.vue';
import router from './router';
import PortalVue from 'portal-vue';
import dtt from '@dtt/ui-kit';
import { i18n } from '@/i18n';
import '@/assets/styles/index.scss';

Vue.config.productionTip = false;

Vue.component('default-layout', () => import('@/layouts/default.vue'));
Vue.component('auth-layout', () => import('@/layouts/auth.vue'));
Vue.component('empty-layout', () => import('@/layouts/empty.vue'));
Vue.use(dtt, { i18n: (key: any, value: any) => i18n.t(key, value) });
Vue.use(PortalVue);

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
