import { ApiService } from '@/api/services/core';
import { baseApi } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { ICompanyISNAKData } from '@/libs/interfaces';

export class CompanyService extends ApiService {
  public fetchCompanyByTIN(tin: string) {
    return baseApi.get<ICompanyISNAKData>(ENDPOINTS.company.preregister(tin));
  }
}
