import { observable, action } from 'mobx';
import { RootStores } from '@/store/core/RootStore';
import StoreConstructor from '@/store/core/StoreConstructor';
import services from '@/api/services';
import { IDevicesGroup } from '@/api/services/crService/type';
import {
  IFilterForm,
  IFilterFormCounters,
  IFiscalMemoriesParams,
  IFmExpirationWarning,
  IFiscalMemories,
  FMState,
} from './types';

import { IFNModel, IPaginate, Sort } from '@/libs/interfaces';
import { PAGE_SIZE } from '@/libs/constants';
import { calculateDaysLeft } from '@/utils';

export default class FiscalMemories extends StoreConstructor {
  inProgress = true;
  isLoading = false;
  expirationWarnings: IFmExpirationWarning[] = [];
  fnExpirationWarningDays = 30;

  data: IFiscalMemories[] = [];
  hasNoFm = false;

  public filterData: IFilterForm;
  public fnSearch = '';
  public sort: Sort;
  public pagination: IPaginate;

  filterFormCounters: IFilterFormCounters;

  constructor(stores: RootStores) {
    super(stores);

    this.filterData = { active: true, unassigned: true, archive: false };
    this.sort = { active: '', direction: '' };
    this.pagination = {
      page: 0,
      totalElements: 0,
      totalPages: 0,
      pageSize: PAGE_SIZE,
    };

    this.filterFormCounters = {
      activeCount: 0,
      unassignedCount: 0,
      archiveCount: 0,
    };
  }

  public async ngOnInit() {
    await this.checkForFms();
    if (!this.hasNoFm) {
      this.fetchFiscalMemories();
    }
  }

  get hasFiscalMemories(): boolean {
    return this.data != null && this.data.length > 0;
  }

  handleSort(sort: Sort) {
    this.sort = sort;
    this.fetchFiscalMemories();
  }

  handlePageChange(page: number) {
    this.pagination.page = page;
    this.fetchFiscalMemories();
  }

  changeFilters(filters: IFilterForm) {
    this.filterData = filters;
    this.fetchFiscalMemories();
  }

  searchByFiscal(fiscalNumber: string) {
    this.fnSearch = fiscalNumber;
    this.fetchFiscalMemories();
  }

  async addFiscalMemories(data: IFNModel) {
    try {
      await services.fiscalMemoriesService.addFiscalMemory(data);
      this.fetchFiscalMemories();
    } catch (e) {
      console.warn(e);
    }
  }

  async fetchFiscalMemories() {
    const noFilterSelected =
      this.filterData.active === false &&
      this.filterData.unassigned === false &&
      this.filterData.archive === false;

    if (noFilterSelected) {
      this.data = [];
      return;
    }

    let filter = '';
    filter += this.fnSearch ? `(serialNumber~${this.fnSearch})&` : '';

    if (
      this.filterData.active ||
      this.filterData.unassigned ||
      this.filterData.archive
    ) {
      filter += '(';
      filter += this.filterData.active ? 'state=IN_USE|state=EXPIRED' : '';
      filter += this.filterData.unassigned ? '|state=FREE' : '';
      filter += this.filterData.archive ? '|state=DISABLED|state=LOST' : '';
      filter += ')';
    }

    const params: IFiscalMemoriesParams = {
      size: this.pagination.pageSize,
      page: this.pagination.page,
      sort: this.sort.active
        ? this.sort.active + ',' + this.sort.direction
        : null,
      filter: filter,
    };

    this.inProgress = true;

    try {
      const { data } = await services.fiscalMemoriesService.getFiscalMemoryList(
        params,
      );
      const { totalElements, totalPages, content } = data;
      this.pagination = {
        totalPages,
        totalElements,
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      };
      this.data = this.joinFnActivationDaysLeft(content);
      this.refreshExpirationWarnings(this.data);
      this.inProgress = false;
    } catch (e: any) {
      this.data = [];
      this.inProgress = false;
      console.warn(e);
    }
  }

  joinFnActivationDaysLeft(data: IFiscalMemories[]) {
    return data.map((fn) => {
      return {
        ...fn,
        fnActivationDaysLeft: calculateDaysLeft(new Date(fn.expirationDate)),
      };
    });
  }

  private refreshExpirationWarnings(fiscalMemories: IFiscalMemories[]) {
    this.expirationWarnings = this.mapExpirationWarnings(fiscalMemories);
  }

  private mapExpirationWarnings(
    fiscalMemories: IFiscalMemories[] = [],
  ): IFmExpirationWarning[] {
    return fiscalMemories
      .filter((fm: IFiscalMemories) => {
        return (
          fm.state === FMState.IN_USE &&
          fm.fnActivationDaysLeft &&
          fm.fnActivationDaysLeft < this.fnExpirationWarningDays
        );
      })
      .map((fm: IFiscalMemories) => {
        return {
          fnSerialNumber: fm.serialNumber,
          daysLeft: fm.fnActivationDaysLeft,
        } as IFmExpirationWarning;
      });
  }

  private async checkForFms() {
    const params = {
      size: this.pagination.pageSize,
      page: 0,
      sort: null,
      filter:
        '(state=IN_USE|state=EXPIRED|state=FREE|state=DISABLED|state=LOST)',
    };

    this.inProgress = true;

    try {
      const { data } = await services.fiscalMemoriesService.getFiscalMemoryList(
        params,
      );
      const { content } = data;
      this.hasNoFm = !content || !content.length;
      this.inProgress = false;
    } catch (e: any) {
      this.inProgress = false;
      console.warn(e);
    }
  }
}
