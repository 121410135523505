export type ApiFilter = CompositeFilter | FieldFilter;

//---------------- Композитные Фильтры -----
abstract class CompositeFilter {
  children: ApiFilter[] = [];
  type = '';
}

export class And extends CompositeFilter {
  type = 'AND';
}

export class Or extends CompositeFilter {
  type = 'OR';
}

export class Not extends CompositeFilter {
  type = 'NOT';
}

//---------------- Фильтры на поле     -----
abstract class FieldFilter {
  field: string;
  values: string[];
  type = '';

  constructor(field: string, values: string[] = []) {
    this.field = field;
    this.values = values;
  }
}

export class FieldEq extends FieldFilter {
  type = 'EQ';
}

export class FieldNe extends FieldFilter {
  type = 'NE';
}

export class FieldLt extends FieldFilter {
  type = 'LT';
}

export class FieldGt extends FieldFilter {
  type = 'GT';
}

export class FieldLte extends FieldFilter {
  type = 'LTE';
}

export class FieldGte extends FieldFilter {
  type = 'GTE';
}

export class FieldBtw extends FieldFilter {
  type = 'BTW';
}

export class FieldLike extends FieldFilter {
  type = 'LIKE';
}

export class FieldIlike extends FieldFilter {
  type = 'ILIKE';
}

export class FieldIn extends FieldFilter {
  type = 'IN';
}

export class FieldIsNull extends FieldFilter {
  type = 'ISNULL';
}

export class FieldNotNull extends FieldFilter {
  type = 'NOTNULL';
}
