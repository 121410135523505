import { ISpringPageable } from '@/libs/interfaces';

export interface IPaginatorState {
  isDataLoaded: boolean;
  hasNextPage: boolean | number;
  hasPrevPage: boolean;
  pageNumber: number;
  totalPages: number;
  totalItems: number;
  currentStartOffset: number;
  currentEndOffset: number;
}

export interface IFilterForm {
  active: boolean;
  unassigned: boolean;
  archive: boolean;
}

export interface IFilterFormCounters {
  activeCount: number;
  unassignedCount: number;
  archiveCount: number;
}

export interface IPaginate {
  page: number;
  totalPages: number;
  totalElements: number;
}

export interface IFiscalMemories {
  serialNumber: string;
  activationDate: string;
  expirationDate: string;
  crRegNumber: string;
  state: string;
  fnActivationDaysLeft?: number;
}

export interface IFiscalMemoriesResponse extends ISpringPageable {
  content: IFiscalMemories[];
}

export interface IFiscalMemoriesParams {
  size: number;
  page: number;
  sort: string | null;
  filter: string;
}

export interface IFmExpirationWarning {
  daysLeft: number;
  fnSerialNumber: string;
}

export enum FMState {
  NEW = 'FREE',
  AWAITING = 'AWAITING',
  IN_USE = 'IN_USE',
  DISABLED = 'DISABLED',
  LOST = 'LOST',
  EXPIRED = 'EXPIRED',
}
