import { UserService } from '@/api/services/userService';
import { OpenService } from '@/api/services/openService';
import { CRService } from '@/api/services/crService';
import { FiscalMemoriesService } from '@/api/services/fiscalMemories';
import { NotificationService } from '@/api/services/notifications';
import { NomenclatureService } from '@/api/services/nomenclature';
import { CurrencyService } from '@/api/services/currency';
import { InfoService } from '@/api/services/infoService';
import { CrDetailsService } from '@/api/services/crDetails';
import { CrTransactionsListService } from '@/api/services/transactionList';
import { CompanyService } from '@/api/services/company';
import { ReceiptService } from '@/api/services/receiptsService';
import { StatsService } from '@/api/services/statsService';

export default {
  user: new UserService(),
  open: new OpenService(),
  crService: new CRService(),
  fiscalMemoriesService: new FiscalMemoriesService(),
  notificationService: new NotificationService(),
  nomenclatureService: new NomenclatureService(),
  currencyService: new CurrencyService(),
  infoService: new InfoService(),
  crDetailsService: new CrDetailsService(),
  crTransactionsListService: new CrTransactionsListService(),
  companyService: new CompanyService(),
  receiptService: new ReceiptService(),
  statsService: new StatsService(),
};
