import { RootStores } from '@/store/core/RootStore';
import StoreConstructor from '@/store/core/StoreConstructor';
import services from '@/api/services';
import { ICodeName, IFormatAndLogicControlError } from '@/libs/interfaces';

export default class BusinessObjectsDictionaryStore extends StoreConstructor {
  constructor(stores: RootStores) {
    super(stores);
  }

  private _entrepreneurshipObjects: ICodeName[] = [];
  public get entrepreneurshipObjects(): ICodeName[] {
    return this._entrepreneurshipObjects;
  }

  private _businessActivities: ICodeName[] = [];
  public get businessActivities(): ICodeName[] {
    return this._businessActivities;
  }

  private _taxAuthorityDepartments: ICodeName[] = [];
  public get taxAuthorityDepartments(): ICodeName[] {
    return this._taxAuthorityDepartments;
  }

  private _legalPersonActivities: ICodeName[] = [];
  public get legalPersonActivities(): ICodeName[] {
    return this._legalPersonActivities;
  }

  private _formatAndLogicControlErrors: IFormatAndLogicControlError[] = [];
  public get formatAndLogicControlErrors(): IFormatAndLogicControlError[] {
    return this._formatAndLogicControlErrors;
  }

  private get isLoaded(): boolean {
    return (
      this.entrepreneurshipObjects.length > 0 &&
      this.businessActivities.length > 0 &&
      this.taxAuthorityDepartments.length > 0 &&
      this.formatAndLogicControlErrors.length > 0
    );
  }

  public async loadDictionaries(): Promise<void> {
    if (this.isLoaded) {
      return;
    }

    await Promise.all([
      this.getEntrepreneurshipObjects(),
      this.getBusinessActivities(),
      this.getTaxAuthorityDepartments(),
      this.getLegalPersonActivities(),
      this.getFormatAndLogicControlErrors(),
    ]);
  }

  private async getEntrepreneurshipObjects(): Promise<void> {
    try {
      const { data } = await services.open.getEntrepreneurshipObjects();
      this._entrepreneurshipObjects = data;
    } catch (e) {
      console.error(e);
    }
  }

  private async getBusinessActivities(): Promise<void> {
    try {
      const { data } = await services.open.getBusinessActivities();
      this._businessActivities = data;
    } catch (e) {
      console.error(e);
    }
  }

  private async getTaxAuthorityDepartments(): Promise<void> {
    try {
      const { data } = await services.open.getTaxAuthorityDepartments();
      this._taxAuthorityDepartments = data;
    } catch (e) {
      console.error(e);
    }
  }

  private async getLegalPersonActivities(): Promise<void> {
    try {
      const { data } = await services.open.getLegalPersonActivities();
      this._legalPersonActivities = data;
    } catch (e) {
      console.error(e);
    }
  }

  private async getFormatAndLogicControlErrors(): Promise<void> {
    try {
      const { data } = await services.open.getFormatAndLogicControlErrors();
      this._formatAndLogicControlErrors = data;
    } catch (e: any) {
      console.error(e);
    }
  }
}
