import { ILocalizedJson } from '@/types/ILocalizedJson';
import { IEntityDto } from '@/types/IEntityDto';
import { IDictionaryEntityDto } from '@/types/IDictionaryEntityDto';
import { IJobInfoDto } from '@/types/IJobInfoDto';

export enum JobExecutionStatusCode {
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
  FINISHED = 'FINISHED',
}

export interface IJobExecutionDto extends IEntityDto {
  // Задача
  jobInfo: IJobInfoDto;

  // Статус исполнения задачи
  status: IDictionaryEntityDto;

  flow: any;

  // сообщение об ошибке
  error: string;

  parent: any;

  // Результат запуска задачи (READ-ONLY)
  params: ILocalizedJson;

  // Тип задачи
  jobType: IDictionaryEntityDto;

  // Результат запуска задачи (???)
  result: IJobExecutionResultDto;
}

export interface IJobExecutionResultDto {
  __flowResult: number;
  violationsClosed: number;
  violationsOpened: number;
}

export interface IJobExecutionListItemView {
  id: string;
  jobInfoId: string;
  title: string;
  version: string;
  created: string;
  updated: string;
  category: string;
  source: string;
  statusCode: string;
  statusCodeColorStyle: string;
  statusTitle: string;

  isFailedResult: boolean;
  failedResult: string;
  violationsClosed: number;
  violationsOpened: number;
}
