export class CounterRow {
  name: string;
  income?: number;
  incomeReturn?: number;
  expense?: number;
  expenseReturn?: number;
  isMoney: boolean;
  cssClass?: string;

  constructor(
    name: string,
    income?: number,
    incomeReturn?: number,
    expense?: number,
    expenseReturn?: number,
    cssClass: string | undefined = undefined,
    isMoney = false,
  ) {
    this.name = name;
    this.income = income;
    this.incomeReturn = incomeReturn;
    this.expense = expense;
    this.expenseReturn = expenseReturn;
    this.cssClass = cssClass;
    this.isMoney = isMoney;
  }

  static simple(
    name: string,
    income?: number,
    incomeReturn?: number,
    expense?: number,
    expenseReturn?: number,
    cssClass: string | undefined = undefined,
  ): CounterRow {
    return new CounterRow(
      name,
      income,
      incomeReturn,
      expense,
      expenseReturn,
      cssClass,
    );
  }

  static money(
    name: string,
    income?: number,
    incomeReturn?: number,
    expense?: number,
    expenseReturn?: number,
    cssClass: string | undefined = undefined,
  ): CounterRow {
    return new CounterRow(
      name,
      income,
      incomeReturn,
      expense,
      expenseReturn,
      cssClass,
      true,
    );
  }
}
