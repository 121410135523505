import { createUrl } from '@/utils';

const lk = createUrl('api/lk');

const cashRegister = lk('cash-register');
const cashRegisterStats = lk('cash-register/stats');
const cashRegisterStatsEndpoint = (from: string, to: string) =>
  lk(`cash-register/stats/from/${from}/to/${to}`);
const agentCashRegistersEndpoint = lk('legal-person/agent-cash-registers');
const cashRegisterByLocation = (locationId: number) =>
  lk(`cash-register/by/location/${locationId}`);

const fiscalMemories = lk('legal-person/list/fn');
const addFiscalMemory = (fnNumber: string, fvalidationCode: string) =>
  lk(`fiscalmemory/${fnNumber}/cvv/${fvalidationCode}`);

const uploadNomenclature = lk('legal-person/nomenclature');

const qr = 'open/pdf';

export const lkEndpoints = {
  cashRegister,
  cashRegisterByLocation,
  fiscalMemories,
  addFiscalMemory,
  uploadNomenclature,
  cashRegisterStats,
  agentCashRegistersEndpoint,
  cashRegisterStatsEndpoint,
  qr,
};
